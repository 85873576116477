<div class="modal-body">
  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close"
    (click)="onHandleClose()">
    <span [inlineSVG]="'./assets/svg/close.svg'"></span>
  </button>

  <h4>{{ 'modals.add_care_module.title' | translate }}</h4>

  <div [ngSwitch]="currentStep">
    <div *ngSwitchCase="stepsEnum.NAME">
      <app-care-module-name [(name)]="name" [(description)]="description" [(type)]="type"
        [(nameStepValid)]="nameStepValid"></app-care-module-name>
    </div>

    <div *ngSwitchCase="stepsEnum.TEMPLATE">
      <app-care-module-pathway [(pathwayTemplate)]="pathwayTemplate"
        [(pathwayTemplateValid)]="pathwayTemplateValid" [(configurationParameters)]="configurationParameters"></app-care-module-pathway>
    </div>

    <div *ngSwitchCase="stepsEnum.TEAM">
      <app-care-module-team [(caseManager)]="caseManager" [(clinicalLead)]="clinicalLead" [(teamValid)]="teamValid" [cmType]="type"
        [hospitalUid]="hospitalUid"></app-care-module-team>
    </div>
  </div>
</div>

<div class="modal-footer">
  <p class="text-muted small">{{ 'modals.add_care_module.step' | translate }} {{ getStepIndex() }} {{
    'modals.add_care_module.of' |
    translate }} {{steps.length}}</p>

  <div class="m-start-auto">
    <button *ngIf="showBackButton()" (click)="onHandleStep('backward')" class="btn btn-link">{{ 'action.previous' |
      translate }}</button>
    <button *ngIf="showNextButton()" [ngClass]="{'disabled': !isButtonEnabled()}" (click)="onHandleStep('forward')"
      class="btn btn-secondary m-start-3">{{ 'action.next' | translate }}</button>

    <button *ngIf="showSubmitButton()" (click)="onHandleSubmit()"
      [ngClass]="{'loader': isSaving, 'disabled': !isButtonEnabled()}" class="btn btn-secondary m-start-3">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'modals.add_care_module.create_button' | translate }}</span>
    </button>
  </div>
</div>
