<div class="pathway-step">
  <div class="container">
    <h3>{{ 'modals.add_care_module.pathway_template' | translate }}</h3>

    <div *ngIf="!pathwayTemplate" class="no-template">
      <p>{{ 'modals.add_care_module.no_pathway_template' | translate }}</p>
      <button (click)="openPathwaySelector()" class="btn btn-link pl-0">{{
        'modals.add_care_module.select_pathway_template' | translate }}</button>
    </div>

    <div *ngIf="pathwayTemplate" class="template">
      <div class="row no-gutters">
        <div class="col-8 col-md-6">
          <div class="row no-gutters bg-light p-2 mb-2">
            <div class="col-6 d-flex align-items-center">
              <h3 class="m-0" [inlineSVG]="'./assets/svg/multpage.svg'"></h3>
              <p class="mb-0 text-truncate"> {{pathwayTemplate.name}}</p>
            </div>

            <div class="col-6 text-right">
              <button class="btn btn-link pr-0" (click)="openPathwaySelector()">{{
                'modals.add_care_module.select_different_template' | translate }}</button>
            </div>
          </div>

          <div class="versions" *ngIf="pathwayTemplate?.versions?.length">
            <div class="form-group required">
              <app-select id="version" [placeholder]="('form.labels.version' | translate)" [searchable]="true"
                [hideSelected]="false" [multiple]="false" [items]="pathwayTemplate.versions" [bindLabel]="'major_version'" [bindValue]="'major_version'"
                [(ngModel)]="pathwayTemplate.major_version" (ngModelChange)="handleVersionChange()"></app-select>
            </div>
            <p class="small text-muted" *ngIf="pathwayTemplate?.major_version && !isLatestVersion()">{{
              'modals.add_care_module.not_latest_version' | translate }}</p>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="pathwayTemplate?.major_version">
      <hr>

      <div class="d-flex justify-content-between">
        <h3>{{ 'modals.add_care_module.pathway_parameters' | translate }}</h3>
        <button *ngIf="configurationParameters?.length && configurationParametersClone?.length" (click)="resetChanges()"
          class="btn btn-link pr-0">{{ 'modals.add_care_module.reset_changes' | translate
          }}</button>
      </div>

      <app-page-loader *ngIf="isLoadingParameters"></app-page-loader>
      
      <div *ngIf="!isLoadingParameters">
        <p *ngIf="!configurationParameters?.length">
          {{ 'modals.add_care_module.no_pathway_template_parameters' | translate }}
        </p>

        <form [formGroup]="paramForm" *ngIf="configurationParameters?.length"
          [ngClass]="{'validation-visible': validationVisible}">
          <ul class="parameters-list">
            <li class="mb-2">
              <div class="row align-items-center px-1">
                <div class="col-7 col-md-8 bg-light py-1">
                  <div class="row">
                    <div class="col-4">
                      <p class="small text-muted mb-0">{{ 'modals.add_care_module.key' | translate }}</p>
                    </div>
                    <div class="col-5">
                      <p class="small text-muted mb-0">{{ 'modals.add_care_module.description' | translate }}</p>
                    </div>
                    <div class="col-3">
                      <p class="small text-muted mb-0">{{ 'modals.add_care_module.type' | translate }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-5 col-md-4 bg-light py-1">
                  <p class="small text-muted mb-0">{{ 'modals.add_care_module.default_value' | translate }}</p>
                </div>
              </div>
            </li>

            <li class="mb-1" *ngFor="let param of configurationParameters">
              <div class="row px-1">
                <div class="col-7 col-md-8">
                  <div class="row py-2" [ngClass]="paramForm.get(param.key)?.dirty ? 'bg-updated' : 'bg-light'">
                    <div class="col-4">
                      <p class="mb-0 text-truncate">{{param.key}}</p>
                    </div>
                    <div class="col-5">
                      <p class="mb-0 text-truncate">{{param.description}}</p>
                    </div>
                    <div class="col-3">
                      <p class="mb-0 text-truncate">{{param.data_type}}</p>
                    </div>
                  </div>
                </div>

                <div class="col-5 col-md-4">
                  <div [ngSwitch]="param.data_type" class="h-100">
                    <div *ngSwitchCase="configurationsParameterType?.DATE">
                      <div class="form-group styled mb-0">
                        <app-date-input [formControlName]="param.key" [name]="param.key"></app-date-input>
                        <p class="validation-feedback" *ngIf="paramForm.get(param.key)?.errors?.required">{{
                          'form.feedback.field_required' | translate }}</p>
                      </div>
                    </div>

                    <div *ngSwitchCase="configurationsParameterType?.DATE_TIME">
                      <div class="form-group mb-0">
                        <app-date-time-input [formControlName]="param.key" [name]="param.key"></app-date-time-input>
                        <p class="validation-feedback" *ngIf="paramForm.get(param.key)?.errors?.required">{{
                          'form.feedback.field_required' | translate }}</p>
                      </div>
                    </div>

                    <div class="form-group styled floating-label mb-0 h-100 d-flex align-items-center"
                      *ngSwitchCase="configurationsParameterType?.BOOLEAN">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input class="custom-control-input" [id]="'true_'+param.key" type="radio"
                          [formControlName]="param.key" [value]="'true'">
                        <label class="custom-control-label" [for]="'true_'+param.key">
                          {{ 'form.labels.true' | translate }}
                        </label>
                      </div>

                      <div class="custom-control custom-radio custom-control-inline">
                        <input class="custom-control-input" [id]="'false_'+param.key" type="radio"
                          [formControlName]="param.key" [value]="'false'">
                        <label class="custom-control-label" [for]="'false_'+param.key">
                          {{ 'form.labels.false' | translate }}
                        </label>
                      </div>

                      <app-feedback-field [field]="paramForm.get(param.key)" [extraClass]="'general-feedback'">
                      </app-feedback-field>
                    </div>


                    <div class="form-group styled no-infield-label mb-0"
                      *ngSwitchCase="configurationsParameterType?.STRING">
                      <input [placeholder]="'modals.add_care_module.default_value' | translate"
                        class="form-control w-100" type="text" [name]="param.key" [id]="'field_'+param.key"
                        [formControlName]="param.key">
                    </div>


                    <div class="form-group styled no-infield-label mb-0"
                      *ngSwitchCase="configurationsParameterType?.INTEGER">
                      <input [placeholder]="" class="form-control w-100" type="number" [id]="'field_'+param.key"
                        [name]="param.key" [formControlName]="param.key">
                      <app-feedback-field [field]="paramForm.get(param.key)">
                      </app-feedback-field>
                    </div>

                    <div class="form-group styled no-infield-label mb-0"
                      *ngSwitchCase="configurationsParameterType?.DOUBLE">
                      <input [placeholder]="" class="form-control w-100" type="number" [id]="'field_'+param.key"
                        [name]="param.key" [formControlName]="param.key">
                      <app-feedback-field [field]="paramForm.get(param.key)">
                      </app-feedback-field>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </form>

      </div>
    </div>
  </div>
</div>
