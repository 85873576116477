<div class="layout-header-pusher header-back-tabs"></div>

<div class="layout-header header-back-tabs">
  <div class="header-content">
    <div class="container">
      <p>
        <a [routerLink]="['/hospitals', hospitalUid, 'care_modules']" class="text-secondary font-weight-bold d-flex align-items-center">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'./assets/svg/left-arrow-big.svg'"></span>
          <span class="align-middle">{{ 'action.back_to_hospital' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col col-auto">
          <h1 *ngIf="careModule">{{ careModule.defaultName }}</h1>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <app-care-module-tabs [active]="'settings'" [hospital_uid]="hospitalUid"
            [care_module]="careModule"></app-care-module-tabs>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">
    <!-- Start: Name & Description -->
    <div class="action-item-block mb-1">
      <div class="block-wrapper p-start-3 p-end-1 w-100">
        <div class="row align-items-start">
          <div class="col-auto icon-column">
            <span class="icon" [inlineSVG]="'./assets/svg/info.svg'"></span>
          </div>
          <div class="col">
            <div class="row">
              <div class="col">
                <h3>{{ 'pages.default.care_module.settings.name_and_description' | translate }}</h3>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="tiny m-0">{{ 'pages.default.care_module.settings.name' | translate }}</label>
                <p id="name">{{ careModule.defaultName }}</p>
              </div>
              <div class="col-8">
                <label class="tiny m-0">{{ 'pages.default.care_module.settings.description' | translate }}</label>
                <p id="description">{{ careModule.defaultDescription }}</p>
              </div>
              <div class="col-4">
                <label class="tiny m-0">{{ 'pages.default.care_module.settings.translations' | translate }}</label>
                <p id="translations">{{ careModule.name.translations.length }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a class="block-action m-start-auto" href="#"
        [routerLink]="['/hospitals/' + hospitalUid + '/care_modules/' + careModule.uid + '/translations']">
        <span [inlineSVG]="'./assets/svg/arrow-right-white.svg'" class="icon m-start-1 rtl-mirrored-inline-svg"></span>
      </a>
    </div>
    <!-- End: Name & Description -->

    <!-- Start: Template -->
    <div class="action-item-block mb-1">
      <div class="block-wrapper p-start-3 p-end-1 w-100">
        <div class="row align-items-start">
          <div class="col-auto icon-column">
            <span class="icon" [inlineSVG]="'./assets/svg/templates.svg'"></span>
          </div>
          <div class="col">
            <div class="row">
              <div class="col">
                <h3>{{ 'pages.default.care_module.settings.pathway_template' | translate }}</h3>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="tiny m-0">{{ 'pages.default.care_module.settings.template' | translate }}</label>
                <p id="name">{{ template.name }}</p>
              </div>
              
              <div class="col-4">
                <label class="tiny m-0">{{ 'pages.default.care_module.settings.parameters' | translate }}</label>
                <p id="params">{{ paramCount }}</p>
              </div>
              
              <div class="col-4">
                <label class="tiny m-0">{{ 'pages.default.care_module.settings.current_major_version' | translate }}</label>
                <p id="major-version">{{ careModule.pathway_template_version }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button class="btn block-action m-start-auto" (click)="editTemplate()">
        <span [inlineSVG]="'./assets/svg/edit.svg'" class="icon m-start-1 rtl-mirrored-inline-svg"></span>
      </button>
    </div>
    <!-- End: Template -->

    <!-- Start: Type / Package -->
    <div class="action-item-block mb-1">
      <div class="block-wrapper p-start-3 p-end-1 w-100">
        <div class="row align-items-start">
          <div class="col-auto icon-column">
            <span class="icon" [inlineSVG]="'./assets/svg/checkboard.svg'"></span>
          </div>
          <div class="col">
            <div class="row">
              <div class="col">
                <h3>{{ 'pages.default.care_module.settings.package' | translate }}</h3>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="tiny m-0">{{ 'pages.default.care_module.settings.type' | translate }}</label>
                <p id="care_module_type">
                  {{ careModule.type | titlecase }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a class="block-action m-start-auto" href="#" (click)="editCareModuleType($event)">
        <span [inlineSVG]="'./assets/svg/edit.svg'" class="icon m-start-1 rtl-mirrored-inline-svg"></span>
      </a>
    </div>
    <!-- End: Type / Package -->

    <!-- Start: Hospital Code -->
    <div class="action-item-block mb-1">
      <div class="block-wrapper p-start-3 p-end-1 w-100">
        <div class="row align-items-start">
          <div class="col-auto icon-column">
            <span class="icon" [inlineSVG]="'./assets/svg/code.svg'"></span>
          </div>
          <div class="col">
            <div class="row">
              <div class="col">
                <h3>{{ 'pages.default.care_module.settings.hospital_code' | translate }}</h3>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="tiny m-0">{{ 'pages.default.care_module.settings.code' | translate }}</label>
                <p id="hospital_code">
                  <span *ngIf="hospitalCode">{{ hospitalCode.code }}</span>
                  <span class="text-info font-weight-bold" *ngIf="!hospitalCode">{{ 'general.not_set' | translate }}</span>
                </p>
              </div>
              
              <div class="col-4" *ngIf="hospitalCode">
                <label class="tiny m-0">{{ 'pages.default.care_module.settings.valid_for' | translate }}</label>
                <p id="hospital_code_validity">
                  <span *ngIf="!hospitalCode.days_valid_indefinite">{{ hospitalCode.days_valid }} {{ 'pages.default.care_module.settings.days' | translate }}</span>
                  <span *ngIf="hospitalCode.days_valid_indefinite">{{ 'pages.default.care_module.settings.no_expiry' | translate }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a class="block-action m-start-auto" href="#" (click)="setHospitalCode($event)">
        <span [inlineSVG]="'./assets/svg/edit.svg'" class="icon m-start-1 rtl-mirrored-inline-svg"></span>
      </a>
    </div>
    <!-- End: Hospital Code -->

    <!-- Start: Default Medical Team -->
    <div class="action-item-block mb-1">
      <div class="block-wrapper p-start-3 p-end-1 w-100">
        <div class="row align-items-start">
          <div class="col-auto icon-column">
            <span class="icon" [inlineSVG]="'./assets/svg/community.svg'"></span>
          </div>
          <div class="col">
            <div class="row">
              <div class="col">
                <h3>{{ 'pages.default.care_module.settings.medical_team' | translate }}</h3>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="tiny m-0">{{ 'pages.default.care_module.settings.case_manager' | translate }}</label>
                <p id="hospital_code">
                  <span *ngIf="defaultCaseManager">{{ defaultCaseManager.getFullName() }}</span>
                  <span *ngIf="!defaultCaseManager">-</span>
                </p>
              </div>
              <div class="col-4">
                <label class="tiny m-0">{{ 'pages.default.care_module.settings.clinical_lead' | translate }}</label>
                <p id="hospital_code">
                  <span *ngIf="defaultClinicalLead">{{ defaultClinicalLead.getFullName() }}</span>
                  <span *ngIf="!defaultClinicalLead">-</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a class="block-action m-start-auto" href="#" (click)="editDefaultMedicalTeam($event)">
        <span [inlineSVG]="'./assets/svg/edit.svg'" class="icon m-start-1 rtl-mirrored-inline-svg"></span>
      </a>
    </div>
    <!-- End: Default Medical Team -->
  </div>
</div>
