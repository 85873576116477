<div class="select-destination-folder">
  <div class="modal-header">
    <h4 class="mb-0">{{ 'modals.select_destination_folder.title' | translate }}</h4>

    <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close"
      (click)="bsModalRef.hide()">
      <span [inlineSVG]="'./assets/svg/close.svg'"></span>
    </button>
  </div>

  <div class="modal-body py-1">
    <div class="container">
      <app-page-loader *ngIf="isLoading"></app-page-loader>


      <ul *ngIf="!isLoading" class="folders items p-0">
        <ng-template #recursiveList let-groups>
          <li *ngFor="let group of groups">
            <div class="template-group d-flex py-1 w-100"
              [ngClass]="{'active': selectedGroup?.path === group.path}">
              <a href="" (click)="toggleGroup(group, $event)">
                <span *ngIf="loadingTemplatesFor != group.name" class="chevron-right" [ngClass]="{'open': group.isOpen}"
                  [inlineSVG]="'./assets/svg/chevron-right.svg'"></span>
                <span *ngIf="loadingTemplatesFor == group.name" class="text-info mr-1"><em
                    class="spinner-border spinner-border-sm"></em></span>
              </a>

              <a href="" class="d-flex align-items-center p-0 mb-0 ml-1 w-100" (click)="selectGroup(group, $event)">
                <span class="mr-1" [inlineSVG]="'./assets/svg/folder-red.svg'"></span>
                <span>{{ group?.name }}</span>
              </a>
            </div>


            <ul *ngIf="group.isOpen">
              <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: group.groups }"></ng-container>

              <!-- <ul>
                <li *ngFor="let template of group?.templates" class="template"
                  [ngClass]="{'active': isSelected(template.uid)}">
                  <a href="" class="d-flex align-items-center px-0 py-1 mb-0" (click)="selectTemplate(template, $event)">
                    <span class="mr-1 ml-2" [inlineSVG]="'./assets/svg/template.svg'"></span>
                    <span class="text-truncate">{{ template.name }}</span>
                  </a>
                </li>
              </ul> -->

              <li>
                <a href="" *ngIf="selectedGroup?.path === group.path"
                  class="text-info px-0 py-1 d-flex align-items-center" (click)="addNewFolder($event, group.path)">
                  <span class="icon-small" [inlineSVG]="'./assets/svg/add.svg'"></span>
                  <span class="mx-1" [inlineSVG]="'./assets/svg/folder.svg'"></span>
                  <span>{{ 'modals.select_destination_folder.add_new_folder' | translate }}</span>
                </a>
              </li>
            </ul>

          </li>
        </ng-template>

        <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: groups }"></ng-container>

        <!-- <li *ngFor="let template of templates" class="templates">
        <a href="" class="d-flex align-items-center px-0 py-1 mb-0 template"
          [ngClass]="{'active': isSelected(template.uid)}" (click)="selectTemplate(template, $event)">
          <span class="mr-1 ml-2" [inlineSVG]="'./assets/svg/template.svg'"></span>
          <span class="text-truncate">{{ template.name }}</span>
        </a>
      </li> -->
        <li>
          <a href="" *ngIf="selectedGroup === undefined" class="text-info px-0 py-1 d-flex align-items-center"
            (click)="addNewFolder($event)">
            <span class="icon-small" [inlineSVG]="'./assets/svg/add.svg'"></span>
            <span class="mx-1" [inlineSVG]="'./assets/svg/folder.svg'"></span>
            <span>{{ 'modals.select_destination_folder.add_new_folder' | translate }}</span>
          </a>
        </li>
      </ul>

    </div>
  </div>
  <div class="modal-footer">
    <div class="m-start-auto">
      <button (click)="onHandleClose()" class="btn btn-link">{{ 'action.cancel' | translate }}</button>

      <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving, 'disabled': selectedGroup === undefined}"
        class="btn btn-secondary m-start-3">
        <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
        <span>{{ 'action.select' | translate }}</span>
      </button>
    </div>
  </div>
</div>
