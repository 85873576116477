<div class="layout-header-pusher header-title-only"></div>
<div class="layout-page-wrapper">
  <div class="layout-header">
    <div class="header-content">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">
            <h1>{{ 'pages.default.gdpr_admin.title' | translate }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="alert alert-info body-content py-2 m-0 border-0">
    <div class="row">
      <div class="col col-auto">
        <span [inlineSVG]="'./assets/svg/caution.svg'" class="icon icon-lg"></span>
      </div>
      <div class="col col-auto">
        <h3 class="alert-heading mb-1">{{ 'pages.default.gdpr_admin.alert.title' | translate }}</h3>
        <p class="m-0" [innerHTML]="'pages.default.gdpr_admin.alert.description' | translate"></p>
      </div>
    </div>
  </div>

  <div class="body-content">
    <div class="container">

      <div class="action-item-block mb-3">
        <div class="block-wrapper w-100">
          <h3>{{ 'pages.default.gdpr_admin.overview.export.title' | translate }}</h3>
          <p class="text-muted m-0" [innerHTML]="'pages.default.gdpr_admin.overview.export.description' | translate"></p>
        </div>

        <a class="block-action m-start-auto" href="" (click)="export($event)">
          <span [inlineSVG]="'./assets/svg/arrow-right-white.svg'" class="icon rtl-mirrored-inline-svg"></span>
        </a>
      </div>

      <div class="action-item-block mb-3">
        <div class="block-wrapper w-100">
          <h3>{{ 'pages.default.gdpr_admin.overview.restrict.title' | translate }}</h3>
          <p class="text-muted m-0" [innerHTML]="'pages.default.gdpr_admin.overview.restrict.description' | translate"></p>
        </div>

        <a class="block-action m-start-auto" href="" (click)="restrict($event)">
          <span [inlineSVG]="'./assets/svg/arrow-right-white.svg'" class="icon rtl-mirrored-inline-svg"></span>
        </a>
      </div>

      <div class="action-item-block">
        <div class="block-wrapper w-100">
          <h3>{{ 'pages.default.gdpr_admin.overview.delete.title' | translate }}</h3>
          <p class="text-muted m-0" [innerHTML]="'pages.default.gdpr_admin.overview.delete.description' | translate"></p>
        </div>

        <a class="block-action m-start-auto" href="" (click)="delete($event)">
          <span [inlineSVG]="'./assets/svg/arrow-right-white.svg'" class="icon rtl-mirrored-inline-svg"></span>
        </a>
      </div>

    </div>
  </div>

</div>
