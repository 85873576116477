<div class="modal-body">
  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'./assets/svg/close.svg'"></span>
  </button>

  <h4>{{ 'modals.add_application.add_application' | translate }}</h4>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <div class="mt-3">
      <label class="small d-block required">Search on</label>

      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input type="radio" class="custom-control-input" id="name" formControlName="searchOn" value="name">
        <label class="custom-control-label" for="name">Application Name</label>
      </div>

      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input type="radio" class="custom-control-input" id="uid" formControlName="searchOn" value="uid">
        <label class="custom-control-label" for="uid">Application UID</label>
      </div>
    </div>

    <br>
    <p>
      <strong>Application(s)</strong>
    </p>
    <ng-select
      (ngModelChange)="onAddApp($event)"
      (search)="searchAppListEvent.next($event)"
      [(ngModel)]="selectedApp"
      [ngModelOptions]="{standalone: true}"
      [items]="appSearchResults"
      [placeholder]="'Search for an application'"
      [searchFn]="customSearchFn"
      [closeOnSelect]="false"
      class="multi-select ng-select mb-2"
      id="inputApps"
      [loading]="isSearching"
    >
      <ng-template let-item="item" ng-label-tmp>
        <span *ngIf="item">{{ item?.name }}</span>
      </ng-template>

      <ng-template ng-loadingspinner-tmp>
        <span class="loader"><em class="spinner-border spinner-border-sm"></em></span>
      </ng-template>

      <ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
        <div *ngIf="item" class="wrapper" (click)="onAddApp(item)">
          <div class="option-info">
            <p class="title">{{ item?.name }}</p>
            <p class="sub-text text-muted">{{ item?.uid }}</p>
          </div>
          <div *ngIf="isAppSelected(item)" class="status small text-muted" >
            <span>Already added</span>
          </div>
          <div *ngIf="!isAppSelected(item)" class="status small text-secondary">
            <span [inlineSVG]="'./assets/svg/add.svg'" class="icon"></span>
            <span>Add</span>
          </div>
        </div>
      </ng-template>
    </ng-select>

    <div *ngFor="let app of apps" class="selected-app bg-light padding p-2 mb-2 d-flex justify-content-between">
      <div>
        <strong class="name">{{ app?.name }}</strong> <br>
        <span class="uid">{{ app?.uid }}</span>
      </div>
      <span class="remove my-auto" (click)="onRemoveApp(app)">
        <span [inlineSVG]="'./assets/svg/close.svg'"></span>
      </span>
    </div>
    
    <p class="text-muted" *ngIf="!apps?.length">No applications selected yet</p>
  </form>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link" >{{ 'action.cancel' | translate }}</button>

    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'modals.add_application.add_application' | translate }}</span>
    </button>
  </div>
</div>
