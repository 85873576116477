<div class="select-pathway-folder">
  <div class="modal-header">
    <h4 class="mb-0">{{ 'modals.select_pathway_folder.title' | translate }}</h4>

    <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close"
      (click)="bsModalRef.hide()">
      <span [inlineSVG]="'./assets/svg/close.svg'"></span>
    </button>
  </div>

  <div class="modal-body py-0">

    <div *ngIf="isLoading" class="container">
      <app-page-loader></app-page-loader>
    </div>

    <div *ngIf="!isLoading" class="container">
      <div class="form-group styled floating-label mt-2">
        <input type="text" id="searchTemplateName" class="form-control w-100"
          [placeholder]="('pages.default.templates.search_template_name' | translate)" [(ngModel)]="filter.name"
          (ngModelChange)="searchTemplateListEvent.next($event)" #searchTemplateName />
        <label for="searchTemplateName">{{ 'pages.default.templates.search_template_name' | translate }}</label>
      </div>

      <ul class="folders items p-0">
        <ng-template #recursiveList let-groups>
          <li *ngFor="let group of groups">
            <div class="template-group d-flex py-1 w-100" [ngClass]="{'active': isSelected(group.path)}"
              [ngClass]="{'active': isSelected(group.path)}">
              <a href="" (click)="toggleGroup(group, $event)">
                <span *ngIf="loadingTemplatesFor !== group.path" class="chevron-right ml-1"
                  [ngClass]="{'open': group.isOpen}" [inlineSVG]="'./assets/svg/chevron-right.svg'"></span>
                <span *ngIf="loadingTemplatesFor === group.path" class="text-info mx-1"><em
                    class="spinner-border spinner-border-sm"></em></span>
              </a>

              <a href="" class="d-flex align-items-center p-0 mb-0 ml-1 w-100" (click)="openGroup(group, $event)">
                <span class="mr-1" [inlineSVG]="'./assets/svg/folder-red.svg'"></span>
                <span>{{ group?.name }}</span>
              </a>
            </div>


            <ul *ngIf="group.isOpen">
              <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: group.groups }"></ng-container>

              <ul>
                <li *ngFor="let template of group?.templates" class="template"
                  [ngClass]="{'active': isSelected(template.uid)}">
                  <a href="" class="d-flex align-items-center px-0 py-1 mb-0"
                    (click)="selectTemplate(template, $event)">
                    <span class="mr-1 ml-2" [inlineSVG]="'./assets/svg/template.svg'"></span>
                    <span class="text-truncate">{{ template.name }}</span>
                  </a>
                </li>
              </ul>
            </ul>
          </li>
        </ng-template>

        <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: groups }"></ng-container>

        <li *ngFor="let template of templates" class="templates">
          <a href="" class="d-flex align-items-center px-0 py-1 mb-0 template"
            [ngClass]="{'active': isSelected(template.uid)}" (click)="selectTemplate(template, $event)">
            <span class="mr-1 ml-2" [inlineSVG]="'./assets/svg/template.svg'"></span>
            <span class="text-truncate">{{ template.name }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <div class="modal-footer">
    <div class="m-start-auto">
      <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving, 'disabled': selectedTemplate === undefined}"
        class="btn btn-secondary m-start-3">
        <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
        <span>{{ 'action.select' | translate }}</span>
      </button>
    </div>
  </div>
</div>
