<div class="layout-header-pusher header-title-only"></div>
<div class="layout-page-wrapper">
  <div class="layout-header">
    <div class="header-content">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">
            <h1>{{ 'pages.default.admin_users.title' | translate }}</h1>
          </div>
          <div class="col col-auto">
            <button class="btn btn-sm btn-secondary" 
              (click)="newAdminUser($event)">{{ 'pages.default.admin_users.new' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="body-content">
    <div class="container" *ngIf="isLoading">
      <app-page-loader></app-page-loader>
    </div>

    <div class="container" *ngIf="!isLoading">
      <table class="table admin-table">
        <thead>
          <tr>
            <th scope="col">{{ 'pages.default.admin_users.name' | translate }}</th>
            <th scope="col">{{ 'pages.default.admin_users.email' | translate }}</th>
            <th scope="col" class="center">{{ 'pages.default.admin_users.org_admin' | translate }}</th>
            <th scope="col" class="center">{{ 'pages.default.admin_users.user_admin' | translate }}</th>
            <th scope="col" class="center">{{ 'pages.default.admin_users.hotline' | translate }}</th>
            <th></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of adminUsers; let index = index;">

            <td><strong>{{ user?.first_name }} {{ user?.last_name }}</strong></td>
            <td>{{ user?.email }}</td>
            <td class="center">
              <span *ngIf="user?.isOrgAdmin()" class="icon" [inlineSVG]="'./assets/svg/check-free.svg'"></span>
              <span *ngIf="!user?.isOrgAdmin()" class="icon" [inlineSVG]="'./assets/svg/false.svg'"></span>
            </td>
            <td class="center">
              <span *ngIf="user?.isUserAdmin()" class="icon" [inlineSVG]="'./assets/svg/check-free.svg'"></span>
              <span *ngIf="!user?.isUserAdmin()" class="icon" [inlineSVG]="'./assets/svg/false.svg'"></span>
            </td>
            <td class="center">
              <span *ngIf="user?.isHotline()" class="icon" [inlineSVG]="'./assets/svg/check-free.svg'"></span>
              <span *ngIf="!user?.isHotline()" class="icon" [inlineSVG]="'./assets/svg/false.svg'"></span>
            </td>
            <td></td>
            <td class="action">
              <a href="#" class="action-button" (click)="editAdminUser($event, user)">
                <span [inlineSVG]="'./assets/svg/edit-white.svg'"></span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ng-container *ngIf="!isLoading && totalElements">
      <div class="align-items-center pagination-container">
        <!-- total items -->
        <div class="m-end-auto">{{ totalElements }} {{  totalElements > 1 ?  ('components.pagination.items_total' | translate) : ('components.pagination.items_total_singular' | translate) }}</div>
        <!-- pagination -->
        <div *ngIf="totalPages > 1"><app-pagination [totalPages]="totalPages" [activePage]="currentPage" (pageChange)="onPaginationPageChanged($event)"></app-pagination></div>
        <!-- pagination range -->
        <div class="m-start-auto"><app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)" [rangeOptions]="[5,10]"></app-pagination-range></div>
      </div>
    </ng-container>
  </div>
  
</div>
