<div class="modal-body">
  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'./assets/svg/close.svg'"></span>
  </button>

  <h4>{{ 'modals.edit_hospital.edit_contact' | translate }}</h4>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <div class="row validation-group" formGroupName="phone_number">
      <div class="col-5">
        <div class="form-group styled floating-label required">
          <app-select
            [placeholder]="('form.labels.country_code' | translate)"
            [clearable]="false"
            [searchable]="true"
            [hideSelected]="false"
            [multiple]="false"
            [items]="dialCodeOptions"
            [bindValue]="'value'"

            [autoSortOnLabel]="true"
            [selectedItemsOnTop]="false"

            formControlName="code"

            [asFilter]="false"
            [showCheckboxes]="false"
          ></app-select>
          <app-feedback-field [field]="form.get('phone_number.code')"></app-feedback-field>
        </div>
      </div>
      <div class="col-7">
        <div class="form-group styled floating-label">
          <input [placeholder]="('form.labels.phone_number' | translate)" class="form-control w-100"
                formControlName="number" id="inputPhone" type="tel">
          <label for="inputPhone" class="required">{{ 'form.labels.phone_number' | translate }}</label>
          <app-feedback-field [field]="form.get('phone_number.number')"></app-feedback-field>

        </div>
      </div>
    </div>
    <app-feedback-field [field]="form.get('phone_number')"></app-feedback-field>

    <!-- Email -->
    <div class="form-group styled floating-label"> 
      <input type="text" id="email" class="form-control w-100" [placeholder]="'form.labels.email_address' | translate" formControlName="email">
      <label for="email" class="required">{{ 'form.labels.email_address' | translate }}</label>
      <app-feedback-field [field]="form.get('email')"></app-feedback-field>
    </div>
  </form>
  
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link" >{{ 'action.cancel' | translate }}</button>

    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>
  </div>
</div>
