import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ToastrModule } from 'ngx-toastr';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppSelectComponent } from './components/app-select/app-select.component';
import { AvatarInputComponent } from './components/avatar-input/avatar-input.component';
import { CareModuleTabsComponent } from './components/care-module-tabs/care-module-tabs.component';
import { EnvironmentIndicatorComponent } from './components/environment-indicator/environment-indicator.component';
import { FeedbackFieldComponent } from './components/feedback-field/feedback-field.component';
import { FlowCopyrightsComponent } from './components/flow-copyrights/flow-copyrights.component';
import { FlowWelcomeComponent } from './components/flow-welcome/flow-welcome.component';
import { HospitalTabsComponent } from './components/hospital-tabs/hospital-tabs.component';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { PaginationRangeComponent } from './components/pagination-range/pagination-range.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PasswordPolicyRulesComponent } from './components/password-policy-rules/password-policy-rules.component';
import { PathwayTemplateDetailComponent } from './components/pathway-template-detail/pathway-template-detail.component';
import { TemplateGroupDetailComponent } from './components/template-group-detail/template-group-detail.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { ErrorInterceptor } from './http-interceptors/error-interceptor';
import { HeaderInterceptor } from './http-interceptors/header-interceptor';
import { SessionInterceptor } from './http-interceptors/session-interceptor';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { FlowLayoutComponent } from './layouts/flow-layout/flow-layout.component';
import { AddAdminUserComponent } from './modals/add-admin-user/add-admin-user.component';
import { AddApplicationConsentComponent } from './modals/add-application-consent/add-application-consent.component';
import { AddApplicationModalComponent } from './modals/add-application-modal/add-application-modal.component';
import { AddApplicationToHospitalModalComponent } from './modals/add-application-to-hospital-modal/add-application-to-hospital-modal.component';
import { AddCareCoordinatorModalComponent } from './modals/add-care-coordinator/add-care-coordinator.component';
import { AddCareModuleModalComponent } from './modals/add-care-module-modal/add-care-module-modal.component';
import { AddCmTranslationComponent } from './modals/add-cm-translation/add-cm-translation.component';
import { AddEducationalMaterialModalComponent } from './modals/add-educational-material-modal/add-educational-material-modal.component';
import { AddFaqModalComponent } from './modals/add-faq-modal/add-faq-modal.component';
import { AddFolderModalComponent } from './modals/add-folder-modal/add-folder-modal.component';
import { AddHospitalConsentComponent } from './modals/add-hospital-consent/add-hospital-consent.component';
import { AddHospitalModalComponent } from './modals/add-hospital-modal/add-hospital-modal.component';
import { PathwayTemplateModalComponent } from './modals/pathway-template-modal/pathway-template-modal.component';
import { AddPlatformConsentModalComponent } from './modals/add-platform-consent-modal/add-platform-consent-modal.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { EditAdminUserComponent } from './modals/edit-admin-user/edit-admin-user.component';
import { EditApplicationConsentComponent } from './modals/edit-application-consent/edit-application-consent.component';
import { EditApplicationModalComponent } from './modals/edit-application-modal/edit-application-modal.component';
import { EditCareCoordinatorModalComponent } from './modals/edit-care-coordinator/edit-care-coordinator.component';
import { EditCmTranslationComponent } from './modals/edit-cm-translation/edit-cm-translation.component';
import { EditCmTypeComponent } from './modals/edit-cm-type/edit-cm-type.component';
import { EditContactInformationComponent } from './modals/edit-contact-information/edit-contact-information.component';
import { EditDefaultMedicalTeamComponent } from './modals/edit-default-medical-team/edit-default-medical-team.component';
import { EditEducationalMaterialModalComponent } from './modals/edit-educational-material-modal/edit-educational-material-modal.component';
import { EditFaqModalComponent } from './modals/edit-faq-modal/edit-faq-modal.component';
import { EditHospitalAddressModalComponent } from './modals/edit-hospital-address-modal/edit-hospital-address-modal.component';
import { EditHospitalConsentModalComponent } from './modals/edit-hospital-consent-modal/edit-hospital-consent-modal.component';
import { EditHospitalContactModalComponent } from './modals/edit-hospital-contact-modal/edit-hospital-contact-modal.component';
import { EditHospitalFeaturesComponent } from './modals/edit-hospital-features/edit-hospital-features.component';
import { EditHospitalGeneralInformationModalComponent } from './modals/edit-hospital-general-information-modal/edit-hospital-general-information-modal.component';
import { EditHospitalPasswordPolicyModalComponent } from './modals/edit-hospital-password-policy-modal/edit-hospital-password-policy-modal.component';
import { EditHospitalPreferencesModalComponent } from './modals/edit-hospital-preferences-modal/edit-hospital-preferences-modal.component';
import { EditHospitalRegistrationCodeModalComponent } from './modals/edit-hospital-registration-code-modal/edit-hospital-registration-code-modal.component';
import { EditPasswordComponent } from './modals/edit-password/edit-password.component';
import { EditPersonalInformationComponent } from './modals/edit-personal-information/edit-personal-information.component';
import { EditPlatformConsentModalComponent } from './modals/edit-platform-consent-modal/edit-platform-consent-modal.component';
import { EditPreferencesComponent } from './modals/edit-preferences/edit-preferences.component';
import { LogoutModalComponent } from './modals/logout-modal/logout-modal.component';
import { ProfilePictureModalComponent } from './modals/profile-picture-modal/profile-picture-modal.component';
import { RemoveApplicationConsentComponent } from './modals/remove-application-consent/remove-application-consent.component';
import { SelectDestinationFolderModalComponent } from './modals/select-destination-folder-modal/select-destination-folder-modal.component';
import { SelectEnvironmentComponent } from './modals/select-environment/select-environment.component';
import { SetHospitalCodeComponent } from './modals/set-hospital-code/set-hospital-code.component';
import { AccountSettingsComponent } from './pages/default/account-settings/account-settings.component';
import { AdminUsersComponent } from './pages/default/admin-users/admin-users.component';
import { GDPRAdminComponent } from './pages/default/gdpr-admin/gdpr-admin.component';
import { GDPRExportModalComponent } from './modals/gdpr-export-modal/gdpr-export-modal.component';
import { GDPRRestrictModalComponent } from './modals/gdpr-restrict-modal/gdpr-restrict-modal.component';
import { GDPRDeleteModalComponent } from './modals/gdpr-delete-modal/gdpr-delete-modal.component';
import { ApplicationConsentsComponent } from './pages/default/application-consents/application-consents.component';
import { ApplicationsComponent } from './pages/default/applications/applications.component';
import { CareModuleFaqsComponent } from './pages/default/care-module-faqs/care-module-faqs.component';
import { CareModuleLearningMaterialsComponent } from './pages/default/care-module-learning-materials/care-module-learning-materials.component';
import { CareModuleSettingsComponent } from './pages/default/care-module-settings/care-module-settings.component';
import { CareModuleTranslationsComponent } from './pages/default/care-module-translations/care-module-translations.component';
import { ConsentsOverviewComponent } from './pages/default/consents-overview/consents-overview.component';
import { HospitalApplicationsComponent } from './pages/default/hospital-applications/hospital-applications.component';
import { HospitalCareCoordinatorsComponent } from './pages/default/hospital-care-coordinators/hospital-care-coordinators.component';
import { HospitalCareModulesComponent } from './pages/default/hospital-care-modules/hospital-care-modules.component';
import { HospitalConsentsComponent } from './pages/default/hospital-consents/hospital-consents.component';
import { HospitalComponent } from './pages/default/hospital/hospital.component';
import { HospitalsComponent } from './pages/default/hospitals/hospitals.component';
import { PathwayTemplatesComponent } from './pages/default/pathway-templates/pathway-templates.component';
import { CamundaCockpitLinksComponent } from './pages/default/camunda-cockpit-links/camunda-cockpit-links.component';
import { ExpiredPasswordResetComponent } from './pages/flow/expired-password-reset/expired-password-reset.component';
import { ForgotPasswordResetComponent } from './pages/flow/forgot-password-reset/forgot-password-reset.component';
import { ForgotPasswordComponent } from './pages/flow/forgot-password/forgot-password.component';
import { HomePageComponent } from './pages/flow/home-page/home-page.component';
import { MfaCodeComponent } from './pages/flow/mfa-code/mfa-code.component';
import { OnboardingComponent } from './pages/flow/onboarding/onboarding.component';
import { SecurePipe } from './pipes/secure.pipe';
import { PlatformConfigurationComponent } from './pages/platform-configuration/platform-configuration.component';
import { EditOtherEmailModalComponent } from './modals/edit-other-email-modal/edit-other-email-modal.component';
import { CareModuleNameComponent } from './components/care-module-name/care-module-name.component';
import { CareModulePathwayComponent } from './components/care-module-pathway/care-module-pathway.component';
import { SelectPathwayFolderModalComponent } from './modals/select-pathway-folder-modal/select-pathway-folder-modal.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { DateTimeInputComponent } from './components/date-time-input/date-time-input.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { CareModuleTeamComponent } from './components/care-module-team/care-module-team.component';
import { AddPathwayTemplateVersionModalComponent } from './modals/add-pathway-template-version-modal/add-pathway-template-version-modal.component';
import { ConfigurationParameterComponent } from './modals/configuration-parameter/configuration-parameter.component';
import { PathwayTemplateErrorModalComponent } from './modals/pathway-template-error-modal/pathway-template-error-modal.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TimePipe } from './pipes/time.pipe';
import { TimeZoneDatePipe } from './pipes/tz_date.pipe';
import { PathwayTemplateVersionDetailComponent } from './components/pathway-template-version-detail/pathway-template-version-detail.component';
import { TimeZoneDateTimePipe } from './pipes/tz_date_time.pipe';
import { CareModalEditTemplateComponent } from './modals/care-modal-edit-template/care-modal-edit-template.component';
import { EditPlatformSupportedLanguagesComponent } from './modals/edit-platform-supported-languages/edit-platform-supported-languages.component';
import { MaintenanceComponent } from './pages/general/maintenance/maintenance.component';
import { Observable, forkJoin, map } from 'rxjs';
import { TranslationLoaderService } from './services/translation-loader.service';
import { SortByPipe } from './pipes/sort-by.pipe';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}

export function CustomTranslateLoader(http: HttpClient) {
  return {
    getTranslation(lang: string): Observable<any> {
      const localTranslations = new TranslateHttpLoader(http, './assets/i18n/', '.json').getTranslation(lang);
      const serviceTranslations = TranslationLoaderService.GetAllTranslations(http, lang);

      return forkJoin([localTranslations, serviceTranslations]).pipe(
        map(([local, service]) => {
          return { ...local, ...service };
        })
      );
    }
  };
}

@NgModule({ declarations: [
        AppComponent,
        FlowLayoutComponent,
        DefaultLayoutComponent,
        HomePageComponent,
        HospitalsComponent,
        HospitalComponent,
        FeedbackFieldComponent,
        FlowCopyrightsComponent,
        MfaCodeComponent,
        FlowWelcomeComponent,
        FeedbackFieldComponent,
        PaginationComponent,
        PaginationRangeComponent,
        PageLoaderComponent,
        LogoutModalComponent,
        AddHospitalModalComponent,
        HospitalTabsComponent,
        AppSelectComponent,
        AdminUsersComponent,
        GDPRAdminComponent,
        GDPRExportModalComponent,
        GDPRRestrictModalComponent,
        GDPRDeleteModalComponent,
        EditHospitalGeneralInformationModalComponent,
        EditHospitalContactModalComponent,
        EditHospitalAddressModalComponent,
        EditHospitalRegistrationCodeModalComponent,
        EditHospitalPreferencesModalComponent,
        EditHospitalPasswordPolicyModalComponent,
        ApplicationsComponent,
        AddAdminUserComponent,
        EditAdminUserComponent,
        AddApplicationModalComponent,
        EditApplicationModalComponent,
        HospitalApplicationsComponent,
        AddApplicationToHospitalModalComponent,
        HospitalCareCoordinatorsComponent,
        PathwayTemplatesComponent,
        CamundaCockpitLinksComponent,
        HospitalCareModulesComponent,
        HospitalCareCoordinatorsComponent,
        PathwayTemplateModalComponent,
        AddCareModuleModalComponent,
        CareModuleTabsComponent,
        CareModuleFaqsComponent,
        AddFaqModalComponent,
        EditFaqModalComponent,
        ConfirmModalComponent,
        CareModuleLearningMaterialsComponent,
        EditEducationalMaterialModalComponent,
        AddEducationalMaterialModalComponent,
        MfaCodeComponent,
        ForgotPasswordComponent,
        ForgotPasswordResetComponent,
        AddCareCoordinatorModalComponent,
        EditCareCoordinatorModalComponent,
        AccountSettingsComponent,
        EditPasswordComponent,
        PasswordPolicyRulesComponent,
        OnboardingComponent,
        ExpiredPasswordResetComponent,
        EnvironmentIndicatorComponent,
        SelectEnvironmentComponent,
        CareModuleSettingsComponent,
        CareModuleTranslationsComponent,
        AddCmTranslationComponent,
        EditCmTranslationComponent,
        SetHospitalCodeComponent,
        HospitalConsentsComponent,
        ConsentsOverviewComponent,
        AddPlatformConsentModalComponent,
        EditPlatformConsentModalComponent,
        EditHospitalConsentModalComponent,
        SecurePipe,
        EditPersonalInformationComponent,
        AvatarInputComponent,
        ProfilePictureModalComponent,
        EditContactInformationComponent,
        EditPreferencesComponent,
        AddHospitalConsentComponent,
        ApplicationConsentsComponent,
        AddApplicationConsentComponent,
        EditApplicationConsentComponent,
        RemoveApplicationConsentComponent,
        ApplicationConsentsComponent,
        AddApplicationConsentComponent,
        EditApplicationConsentComponent,
        RemoveApplicationConsentComponent,
        AddHospitalConsentComponent,
        EditCmTypeComponent,
        ToggleSwitchComponent,
        EditHospitalFeaturesComponent,
        PathwayTemplateDetailComponent,
        TemplateGroupDetailComponent,
        SelectDestinationFolderModalComponent,
        AddFolderModalComponent,
        EditDefaultMedicalTeamComponent,
        PlatformConfigurationComponent,
        EditOtherEmailModalComponent,
        CareModuleNameComponent,
        CareModulePathwayComponent,
        SelectPathwayFolderModalComponent,
        DateInputComponent,
        DateTimeInputComponent,
        CareModuleTeamComponent,
        AddPathwayTemplateVersionModalComponent,
        ConfigurationParameterComponent,
        PathwayTemplateErrorModalComponent,
        TimePipe,
        TimeZoneDatePipe,
        TimeZoneDateTimePipe,
        PathwayTemplateVersionDetailComponent,
        CareModalEditTemplateComponent,
        EditDefaultMedicalTeamComponent,
        EditPlatformSupportedLanguagesComponent,
        MaintenanceComponent,
        SortByPipe
    ],
    bootstrap: [AppComponent], imports: [ImageCropperModule,
        BrowserModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ClipboardModule,
        InlineSVGModule.forRoot(),
        CommonModule,
        NgSelectModule,
        InfiniteScrollModule,
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        TimepickerModule.forRoot(),
        CollapseModule.forRoot(),
        ToastrModule.forRoot({
            closeButton: false,
            disableTimeOut: true,
            preventDuplicates: true
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: CustomTranslateLoader,
                deps: [HttpClient]
            }
        })], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
        SecurePipe,
        SortByPipe,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
