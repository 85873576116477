<div class="modal-body">
  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'./assets/svg/close.svg'"></span>
  </button>

  <h4>{{ 'modals.edit_features.title' | translate }}</h4>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}" id="form">
    <div class="form-group d-flex justify-content-between pt-3">
      <label class="toggle-label">{{ 'modules.TREATMENTS' | translate }}</label>
      <label class="toggle-label">{{ 'modules.always_on' | translate }}</label>
    </div>
      
    <div class="form-group styled toggle" id="messaging">
      <label class="toggle-label text-dark mb-0">{{ 'modules.MESSAGING' | translate }}</label>
      <app-toggle-switch formControlName="messaging"/>
    </div>

    <div class="form-group styled toggle" id="appointment">
      <label class="toggle-label text-dark mb-0">{{ 'modules.APPOINTMENT' | translate }}</label>
      <app-toggle-switch formControlName="appointment"/>
    </div>
      
    <div formGroupName="analytics">
      <div class="form-group styled toggle" id="analytics-general">
        <label class="toggle-label text-dark mb-0">{{ 'modules.ANALYTICS.general' | translate }}</label>
        <app-toggle-switch formControlName="general"/>
      </div>
      
      <div class="form-group styled toggle m-start-2" id="analytics-pathway-overview">
        <label class="toggle-label mb-0" [ngClass]="{'required': form.get('analytics.general')?.value}">{{ 'modules.ANALYTICS.pathways_overview' | translate }}</label>
        <app-toggle-switch formControlName="pathways_overview"/>
        <app-feedback-field [field]="form.get('analytics.pathways_overview')"/>
      </div>
    
      <div class="form-group styled toggle m-start-2" id="analytics-patient-onboarding">
        <label class="toggle-label mb-0" [ngClass]="{'required': form.get('analytics.general')?.value}">{{ 'modules.ANALYTICS.patient_onboarding' | translate }}</label>
        <app-toggle-switch formControlName="patient_onboarding"/>
        <app-feedback-field [field]="form.get('analytics.patient_onboarding')"/>
      </div>
      
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link" >{{ 'action.cancel' | translate }}</button>

    <button (click)="formSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>
  </div>
</div>
