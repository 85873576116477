<div class="layout-header-pusher header-back-tabs"></div>

<div class="layout-header header-back-tabs">
  <div class="header-content">
    <div class="container">
      <p>
        <a [routerLink]="['/hospitals']" class="text-secondary font-weight-bold d-flex align-items-center">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'./assets/svg/left-arrow-big.svg'"></span>
          <span class="align-middle">{{ 'action.back_to_hospitals' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col col-auto">
          <h1>{{ hospital?.name }}</h1>
        </div>
        <div class="col col-auto">
          <button class="btn btn-sm btn-secondary" (click)="addApplication($event)">{{ 'pages.default.applications.new' | translate }}</button>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <app-hospital-tabs [hospital]="hospital" [active]="'applications'"></app-hospital-tabs>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">
    <table class="table admin-table">
      <thead>
        <tr>
          <th scope="col">Application ID</th>
          <th scope="col">Application name</th>
          <th scope="col">URL</th>
          <th scope="col">Type</th>
          <th scope="col">Accesible by</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let application of applications; let index = index;">
          <td><strong>{{application.uid}}</strong></td>
          <td><strong>{{ application.name }}</strong></td>
          <td>{{application.frontend_url}}</td>
          <td>{{application.type}}</td>
          <td>{{application.accesibleByString()}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <ng-container *ngIf="!isLoading && totalElements">
    <div class="align-items-center pagination-container">
      <!-- total items -->
      <div class="m-end-auto">{{ totalElements }} {{  totalElements > 1 ?  ('components.pagination.items_total' | translate) : ('components.pagination.items_total_singular' | translate) }}</div>
      <!-- pagination -->
      <div *ngIf="totalPages > 1"><app-pagination [totalPages]="totalPages" [activePage]="currentPage" (pageChange)="onPaginationPageChanged($event)"></app-pagination></div>
      <!-- pagination range -->
      <div class="m-start-auto"><app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)" [rangeOptions]="[5,10]"></app-pagination-range></div>
    </div>
  </ng-container>
</div>

<div class="body-content d-flex justify-content-center align-items-center" *ngIf="!isLoading && !applications?.length">
  <div class="text-center pt-5">
    <span class="large-svg" [inlineSVG]="'./assets/svg-color/box.svg'"></span>
    <h3 class="mt-3">No applications have been added.</h3>
  </div>
</div>
