<div class="modal-body">
  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close"
    (click)="bsModalRef.hide()">
    <span [inlineSVG]="'./assets/svg/close.svg'"></span>
  </button>

  <h4>{{ 'modals.edit_hospital.edit_password_policy' | translate }}</h4>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <div formGroupName="security_configuration">
      <div formGroupName="password_policy">
        <div class="form-group required">
          <app-select id="mfa" [placeholder]="('form.labels.use_2_step_authentication' | translate)"
            [searchable]="false" [hideSelected]="false" [multiple]="false" [items]="options" [bindValue]="'value'"
            formControlName="mfa"></app-select>
          <app-feedback-field [field]="form.get('security_configuration.password_policy.mfa')"></app-feedback-field>
        </div>
        <div class="form-group required">
          <app-select id="use_lowercase" [placeholder]="('form.labels.lowercase_required' | translate)"
            [searchable]="false" [hideSelected]="false" [multiple]="false" [items]="options" [bindValue]="'value'"
            formControlName="use_lowercase"></app-select>
          <app-feedback-field [field]="form.get('security_configuration.password_policy.use_lowercase')">
          </app-feedback-field>
        </div>
        <div class="form-group required">
          <app-select id="use_uppercase" [placeholder]="('form.labels.uppercase_required' | translate)"
            [searchable]="false" [hideSelected]="false" [multiple]="false" [items]="options" [bindValue]="'value'"
            formControlName="use_uppercase"></app-select>
          <app-feedback-field [field]="form.get('security_configuration.password_policy.use_uppercase')">
          </app-feedback-field>
        </div>
        <div class="form-group required">
          <app-select id="use_numbers" [placeholder]="('form.labels.numbers_required' | translate)" [searchable]="false"
            [hideSelected]="false" [multiple]="false" [items]="options" [bindValue]="'value'"
            formControlName="use_numbers"></app-select>
          <app-feedback-field [field]="form.get('security_configuration.password_policy.use_numbers')">
          </app-feedback-field>
        </div>
        <div class="form-group required">
          <app-select id="use_specials" [placeholder]="('form.labels.symbols_required' | translate)"
            [searchable]="false" [hideSelected]="false" [multiple]="false" [items]="options" [bindValue]="'value'"
            formControlName="use_specials"></app-select>
          <app-feedback-field [field]="form.get('security_configuration.password_policy.use_specials')">
          </app-feedback-field>
        </div>
        <div class="form-group styled floating-label">
          <input type="text" id="min_length" class="form-control w-100"
            [placeholder]="'form.labels.min_password_length' | translate" formControlName="min_length">
          <label for="min_length" class="required">{{ 'form.labels.min_password_length' | translate }}</label>
          <app-feedback-field [field]="form.get('security_configuration.password_policy.min_length')"
            [meta]="{field_value: {min: pwLengthValidatorMin, max: pwLengthValidatorMax}}"></app-feedback-field>
        </div>
        <div class="form-group styled floating-label">
          <input type="text" id="max_length" class="form-control w-100"
            [placeholder]="'form.labels.max_password_length' | translate" formControlName="max_length">
          <label for="max_length" class="required">{{ 'form.labels.max_password_length' | translate }}</label>
          <app-feedback-field [field]="form.get('security_configuration.password_policy.max_length')"
            [meta]="{field_value: {min: pwLengthValidatorMin, max: pwLengthValidatorMax}}">
          </app-feedback-field>
        </div>

        <div class="form-group">
          <label class="small d-block required">{{ 'form.labels.cant_reuse_password_from_the_past' | translate
            }}</label>

          <div class="custom-control custom-radio custom-control-inline mb-1">
            <input class="custom-control-input" formControlName="history" id="history_yes" type="radio" value="true">
            <label class="custom-control-label" for="history_yes">{{ 'form.labels.yes' | translate }}</label>
          </div>

          <div class="custom-control custom-radio custom-control-inline mb-1">
            <input class="custom-control-input" formControlName="history" id="hisotry_no" type="radio" value="false">
            <label class="custom-control-label" for="hisotry_no">{{ 'form.labels.no' | translate }}</label>
          </div>

          <app-feedback-field [field]="form.get('history')" [extraClass]="'general-feedback'"></app-feedback-field>
        </div>

        <div class="form-group styled floating-label">
          <input type="text" id="history_size" class="form-control w-100"
            [placeholder]="'form.labels.amount_passwords_to_remember' | translate" formControlName="history_size">
          <label for="history_size" class="required">{{ 'form.labels.amount_passwords_to_remember' | translate
            }}</label>
          <app-feedback-field [field]="form.get('security_configuration.password_policy.history_size')"
            [meta]="{field_value: {min: historySizeValidatorMin, max: historySizeValidatorMax}}">
          </app-feedback-field>
        </div>

        <div class="form-group required">
          <app-select id="expires" [placeholder]="('form.labels.password_expires' | translate)" [searchable]="false"
            [hideSelected]="false" [multiple]="false" [items]="booleanList" [bindValue]="'value'"
            formControlName="expires"></app-select>
          <app-feedback-field [field]="form.get('security_configuration.password_policy.expires')"></app-feedback-field>
        </div>

        <div class="form-group styled floating-label">
          <input type="text" id="expires_in_days" class="form-control w-100"
            [placeholder]="'form.labels.amount_days_expires' | translate" formControlName="expires_in_days">
          <label for="expires_in_days" class="required">{{ 'form.labels.amount_days_expires' | translate }}</label>
          <app-feedback-field [field]="form.get('security_configuration.password_policy.expires_in_days')"
            [meta]="{field_value: {min: expiresInDaysValidatorMin, max: expiresInDaysValidatorMax}}">
          </app-feedback-field>
        </div>

        <div class="form-group styled floating-label">
          <input type="text" id="expiry_notification_in_days" class="form-control w-100"
            [placeholder]="'form.labels.expiry_notification_in_days' | translate"
            formControlName="expiry_notification_in_days">
          <label for="expiry_notification_in_days" class="required">{{ 'form.labels.expiry_notification_in_days' |
            translate }}</label>
          <app-feedback-field [field]="form.get('security_configuration.password_policy.expiry_notification_in_days')"
            [meta]="{field_value: {min: expiryNotificationInDaysValidatorMin, max: expiryNotificationInDaysValidatorMax}}">
          </app-feedback-field>
        </div>
      </div>
    </div>
  </form>

</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link">{{ 'action.cancel' | translate }}</button>

    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>
  </div>
</div>
