<div class="modal-body">
  <button type="button" class="close" id="edit-personal-modal-back" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'./assets/svg/close.svg'"></span>
  </button>

  <h2>{{ 'modals.edit_personal_details.title' | translate }}</h2>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <!-- Profile picture -->
    <label class="small">{{ 'form.labels.profile_picture' | translate }}</label>

    <div class="control-profile-picture mb-3">
      <app-avatar-input formControlName="profile_picture"></app-avatar-input>
    </div>

    <!-- First name -->
    <div class="form-group styled floating-label">
      <input type="text" id="inputFirstName" class="form-control w-100" [placeholder]="('form.labels.first_name' | translate)" formControlName="first_name">
      <label for="inputFirstName" class="required">{{ 'form.labels.first_name' | translate }}</label>
      <app-feedback-field [field]="form.get('first_name')"></app-feedback-field>
    </div>

    <!-- Last name -->
    <div class="form-group styled floating-label">
      <input type="text" id="inputLastName" class="form-control w-100" [placeholder]="'.form.labels.last_name' | translate" formControlName="last_name">
      <label for="inputLastName" class="required">{{ 'form.labels.last_name' | translate }}</label>
      <app-feedback-field [field]="form.get('last_name')"></app-feedback-field>
    </div>
  </form>
</div>


<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" id="edit-personal-modal-cancel" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>

    <button class="btn btn-secondary m-start-3" id="edit-personal-modal-confirm" [ngClass]="{'loader': isSaving}" (click)="handleConfirm()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>
  </div>
</div>
