<div class="modal-body">
  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'./assets/svg/close.svg'"></span>
  </button>

  <h4>{{ 'modals.edit_application_consent.title' | translate }}</h4>

  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}" *ngIf="!isLoading">
    <div class="form-group">
      <app-select
        id="consent_key"
        [placeholder]="('form.labels.consent_key' | translate)"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="consentOptions"
        [bindValue]="'value'"
        formControlName="consent_key"
      ></app-select>
      <app-feedback-field [field]="form.get('consent_key')"></app-feedback-field>
    </div>
  </form>
</div>
<div class="modal-footer" *ngIf="!isLoading">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link" >{{ 'action.cancel' | translate }}</button>

    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'modals.edit_application_consent.save' | translate }}</span>
    </button>
  </div>
</div>
