<div class="modal-body">
  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'./assets/svg/close.svg'"></span>
  </button>

  <h4>{{ 'modals.edit_care_coordinator.title' | translate }}</h4>

  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}" *ngIf="!isLoading">
    <!-- Gender -->
    <div class="pb-2">
      <label class="small d-block required">{{ 'form.labels.gender' | translate }}</label>

      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input class="custom-control-input" formControlName="gender" id="radioSexMale" type="radio" value="MALE">
        <label class="custom-control-label" for="radioSexMale">{{ 'form.labels.male' | translate }}</label>
      </div>

      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input class="custom-control-input" formControlName="gender" id="radioSexFemale" type="radio" value="FEMALE">
        <label class="custom-control-label" for="radioSexFemale">{{ 'form.labels.female' | translate }}</label>
      </div>

      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input class="custom-control-input" formControlName="gender" id="radioSexOther" type="radio" value="UNKNOWN">
        <label class="custom-control-label" for="radioSexOther">{{ 'form.labels.other' | translate }}</label>
      </div>

      <app-feedback-field [field]="form.get('gender')" [extraClass]="'general-feedback'"></app-feedback-field>
    </div>

    <!-- First name -->
    <div class="form-group styled floating-label">
      <input type="text" id="first_name" class="form-control w-100" [placeholder]="'form.labels.first_name' | translate" formControlName="first_name">
      <label for="first_name" class="required">{{ 'form.labels.first_name' | translate }}</label>
      <app-feedback-field [field]="form.get('first_name')"></app-feedback-field>
    </div>

    <!-- Preferred name -->
    <div class="form-group styled floating-label">
      <input type="text" id="preferred_name" class="form-control w-100" [placeholder]="'form.labels.preferred_name' | translate" formControlName="preferred_name">
      <label for="preferred_name">{{ 'form.labels.preferred_name' | translate }}</label>
      <app-feedback-field [field]="form.get('preferred_name')"></app-feedback-field>
    </div>

    <!-- Last name -->
    <div class="form-group styled floating-label">
      <input type="text" id="last_name" class="form-control w-100" [placeholder]="'form.labels.last_name' | translate" formControlName="last_name">
      <label for="last_name" class="required">{{ 'form.labels.last_name' | translate }}</label>
      <app-feedback-field [field]="form.get('last_name')"></app-feedback-field>
    </div>

    <!-- Language -->
    <div class="form-group required">
      <app-select
        [placeholder]="('form.labels.language' | translate)"
        [clearable]="false"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="languageOptions"
        [bindValue]="'key'"

        [autoSortOnLabel]="true"
        [selectedItemsOnTop]="false"

        formControlName="language"

        [asFilter]="false"
        [showCheckboxes]="false"
      ></app-select>
      <app-feedback-field [field]="form.get('language')"></app-feedback-field>
    </div>

    <!-- Email -->
    <div class="row mb-2">
      <div class="col">
        <div class="form-group styled floating-label mb-0">
          <input type="text" id="email" class="form-control w-100" formControlName="email">
          <label for="email" class="required">{{ 'form.labels.email_address' | translate }}</label>
          <app-feedback-field [field]="form.get('email')"></app-feedback-field>
        </div>
      </div>
      <div class="col-auto" *ngIf="careCoordinator?.email_change_allowed">
        <button class="btn btn-link h-100" (click)="showEditEmailModal()" id="edit-cc-email">{{ 'modals.edit_care_coordinator.edit_email' | translate}}</button>
      </div>
    </div>

    <!-- Function -->
    <div class="form-group required">
      <app-select
        [placeholder]="('form.labels.function' | translate)"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="functionOptions"
        [bindValue]="'value'"
        formControlName="function"
      ></app-select>
      <app-feedback-field [field]="form.get('function')"></app-feedback-field>
    </div>

    <!-- Job title -->
    <div class="form-group styled floating-label">
      <input type="text" id="job_title" class="form-control w-100" [placeholder]="'form.labels.job_title' | translate" formControlName="job_title">
      <label for="job_title" class="required">{{ 'form.labels.job_title' | translate }}</label>
      <app-feedback-field [field]="form.get('job_title')"></app-feedback-field>
    </div>

    <!-- Phone number -->
    <div class="row validation-group" formGroupName="phone_number">
      <div class="col-5">
        <div class="form-group styled floating-label required">
          <app-select
            [placeholder]="('form.labels.country_code' | translate)"
            [clearable]="false"
            [searchable]="true"
            [hideSelected]="false"
            [multiple]="false"
            [items]="dialCodeOptions"
            [bindValue]="'value'"

            [autoSortOnLabel]="true"
            [selectedItemsOnTop]="false"

            formControlName="code"

            [asFilter]="false"
            [showCheckboxes]="false"
          ></app-select>
           <app-feedback-field [field]="form.get('phone_number.code')"></app-feedback-field>

        </div>
      </div>
      <div class="col-7">
        <div class="form-group styled floating-label">
          <input [placeholder]="('form.labels.phone_number' | translate)" class="form-control w-100"
                 formControlName="number" id="inputPhone" type="tel">
          <label for="inputPhone" class="required">{{ 'form.labels.phone_number' | translate }}</label>
        </div>
      </div>
    </div>
    <app-feedback-field [field]="form.get('phone_number')"></app-feedback-field>
  </form>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link" >{{ 'action.cancel' | translate }}</button>

    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>
  </div>
</div>
