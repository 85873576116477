<div class="modal-body">
  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'./assets/svg/close.svg'"></span>
  </button>

  <h4>{{ 'modals.edit_cm_translation.title' | translate }}</h4>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <div class="form-group">
      <app-select
        id="preference-languages"
        [placeholder]="('form.labels.language' | translate)"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="languages"
        [bindValue]="'key'"
        formControlName="language"
      ></app-select>
      <app-feedback-field [field]="form.get('default_locale.language')"></app-feedback-field>
    </div>
    <div class="form-group styled floating-label">
      <input type="text" id="name" class="form-control w-100" [placeholder]="'form.labels.name' | translate" formControlName="name">
      <label for="name" class="required">{{ 'form.labels.name' | translate }}</label>
      <app-feedback-field [field]="form.get('name')"></app-feedback-field>
    </div>
    <div class="form-group styled floating-label">
      <input type="text" id="description" class="form-control w-100" [placeholder]="'form.labels.description' | translate" formControlName="description">
      <label for="description">{{ 'form.labels.description' | translate }}</label>
      <app-feedback-field [field]="form.get('description')"></app-feedback-field>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link" >{{ 'action.cancel' | translate }}</button>

    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'modals.edit_cm_translation.save' | translate }}</span>
    </button>
  </div>
</div>
