<div class="modal-header">
  <h4 *ngIf="!parameter">{{ 'modals.configuration_parameter.add_parameter_title' | translate }}</h4>
  <h4 *ngIf="parameter">{{ 'modals.configuration_parameter.edit_parameter_title' | translate }}</h4>

  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close"
    (click)="bsModalRef.hide()">
    <span [inlineSVG]="'./assets/svg/close.svg'"></span>
  </button>
</div>

<div class="modal-body py-0">
  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <div class="form-group styled floating-label">
      <input type="text" id="key" class="form-control w-100" [placeholder]="'form.labels.key' | translate"
        formControlName="key">
      <label class="required" for="key">{{ 'form.labels.key' | translate }}</label>
      <app-feedback-field [field]="form.get('key')"></app-feedback-field>
    </div>

    <div class="form-group styled floating-label">
      <input type="text" id="description" class="form-control w-100"
        [placeholder]="'form.labels.description' | translate" formControlName="description">
      <label class="required" for="description">{{ 'form.labels.description' | translate }}</label>
      <app-feedback-field [field]="form.get('description')"></app-feedback-field>
    </div>

    <div class="form-group required">
      <app-select [placeholder]="('form.labels.type' | translate)" [clearable]="false" [searchable]="false"
        [hideSelected]="false" [multiple]="false" [items]="dataTypes" formControlName="dataType" [asFilter]="false"
        [showCheckboxes]="false" [bindLabel]="'value'"></app-select>
      <app-feedback-field [field]="form.get('dataType')"></app-feedback-field>
    </div>

    <div class="form-group floating-label">
      <div [ngSwitch]="form.get('dataType')?.value?.value">
        
        <!-- DATE -->
        <div *ngSwitchCase="configurationsParameterType?.DATE">
          <div class="form-group styled mb-0">
            <app-date-input formControlName="defaultValue" name="defaultValue" [placeholder]="('form.labels.default_value' | translate)"
              [required]="true"></app-date-input>
          </div>
        </div>

        <!-- DATE_TIME -->
        <div *ngSwitchCase="configurationsParameterType?.DATE_TIME">
          <div class="form-group mb-0 required">
            <app-date-time-input formControlName="defaultValue" name="defaultValue"></app-date-time-input>
          </div>
        </div>

        <!-- BOOLEAN -->
        <div class="form-group styled" *ngSwitchCase="configurationsParameterType?.BOOLEAN">
          <label class="required d-block">{{ 'form.labels.default_value' | translate }}</label>
          <div class="custom-control custom-radio custom-control-inline">
            <input class="custom-control-input" id="true-defaultValue" type="radio" formControlName="defaultValue" value="true">
            <label class="custom-control-label" for="true-defaultValue">
              {{ 'form.labels.true' | translate }}
            </label>
          </div>

          <div class="custom-control custom-radio custom-control-inline">
            <input class="custom-control-input" id="false-defaultValue" type="radio" formControlName="defaultValue" value="false">
            <label class="custom-control-label" for="false-defaultValue">
              {{ 'form.labels.false' | translate }}
            </label>
          </div>
        </div>

        <!-- STRING -->
        <div class="form-group styled mb-0" *ngSwitchCase="configurationsParameterType?.STRING">
          <input [placeholder]="'modals.add_care_module.default_value' | translate" class="form-control w-100"
            type="text" name="defaultValue" id="default-value-string" formControlName="defaultValue">
          <label for="default-value-string" class="required">{{ 'form.labels.default_value' | translate }}</label>
        </div>

        <!-- INTEGER -->
        <div class="form-group styled mb-0" *ngSwitchCase="configurationsParameterType?.INTEGER">
          <input [placeholder]="" class="form-control w-100" type="number" id="default-value-int" name="defaultValue"
            formControlName="defaultValue">
          <label for="default-value-int" class="required">{{ 'form.labels.default_value' | translate }}</label>
        </div>

        <!-- DOUBLE -->
        <div class="form-group styled mb-0" *ngSwitchCase="configurationsParameterType?.DOUBLE">
          <input [placeholder]="" class="form-control w-100" type="number" id="default-value-double" name="defaultValue"
            formControlName="defaultValue">
          <label for="default-value-double" class="required">{{ 'form.labels.default_value' | translate }}</label>
        </div>
      </div>

      <app-feedback-field [field]="form.get('defaultValue')">
      </app-feedback-field>

    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link">{{ 'action.cancel' | translate }}</button>

    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span *ngIf="!parameter">{{ 'modals.configuration_parameter.add_parameter' | translate }}</span>
      <span *ngIf="parameter">{{ 'modals.configuration_parameter.edit_parameter' | translate }}</span>
    </button>
  </div>
</div>
