<div class="modal-body">
  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'./assets/svg/close.svg'"></span>
  </button>

  <h4>{{ 'modals.edit_hospital.edit_default_preferences' | translate }}</h4>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <div formGroupName="default_locale">
      <div class="form-group required">
        <app-select
          id="preference-languages"
          [placeholder]="('form.labels.language' | translate)"
          [searchable]="true"
          [hideSelected]="false"
          [multiple]="false"
          [items]="languages"
          [bindValue]="'key'"
          formControlName="language"
        ></app-select>
        <app-feedback-field [field]="form.get('default_locale.language')"></app-feedback-field>
      </div>

      <div class="form-group required">
        <app-select
          id="preference-measurement-unit"
          [placeholder]="('form.labels.units' | translate)"
          [searchable]="true"
          [hideSelected]="false"
          [multiple]="false"
          [items]="measurementUnits"
          [bindValue]="'value'"
          formControlName="measurement_unit"
        ></app-select>
        <app-feedback-field [field]="form.get('default_locale.measurement_unit')"></app-feedback-field>
      </div>

      <div class="form-group required">
        <app-select
          id="preference-number-format"
          [placeholder]="('form.labels.number_format' | translate)"
          [searchable]="true"
          [hideSelected]="false"
          [multiple]="false"
          [items]="numberFormats"
          [bindValue]="'value'"
          formControlName="number_format"
        ></app-select>
        <app-feedback-field [field]="form.get('default_locale.number_format')"></app-feedback-field>
      </div>

      <div class="form-group required">
        <app-select
          id="preference-date-format"
          [placeholder]="('form.labels.date_format' | translate)"
          [searchable]="true"
          [hideSelected]="false"
          [multiple]="false"
          [items]="dateFormats"
          [bindValue]="'value'"
          formControlName="date_format"
        ></app-select>
        <app-feedback-field [field]="form.get('default_locale.date_format')"></app-feedback-field>
      </div>

      <div class="form-group required">
        <app-select
          id="preference-time-zone"
          [placeholder]="('form.labels.time_zone' | translate)"
          [searchable]="true"
          [hideSelected]="false"
          [multiple]="false"
          [items]="timeZones"
          [bindValue]="'value'"
          formControlName="time_zone"
        ></app-select>
        <app-feedback-field [field]="form.get('default_locale.time_zone')"></app-feedback-field>
      </div>

      <div class="form-group required">
        <app-select
          id="preference-time-24-hours"
          [placeholder]="('form.labels.time_format' | translate)"
          [searchable]="false"
          [hideSelected]="false"
          [multiple]="false"
          [items]="timeFormats"
          [bindValue]="'value'"
          formControlName="time_24_hours"
        ></app-select>
        <app-feedback-field [field]="form.get('default_locale.time_24_hours')"></app-feedback-field>
      </div>

      <div class="form-group required">
        <app-select
          id="preference-first-day-of-week"
          [placeholder]="('form.labels.first_day_of_week' | translate)"
          [searchable]="false"
          [hideSelected]="false"
          [multiple]="false"
          [items]="weekdays"
          [bindValue]="'value'"
          formControlName="first_day_of_week"
        ></app-select>
        <app-feedback-field [field]="form.get('default_locale.first_day_of_week')"></app-feedback-field>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link" >{{ 'action.cancel' | translate }}</button>

    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>
  </div>
</div>
