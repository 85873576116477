<div class="modal-header">
  <h4 class="text-capitalize-first">
    <span *ngIf="hasErrors">{{ 'modals.pathway_template_error.errors' | translate }} </span>
    <span *ngIf="hasErrors && hasWarnings">{{ 'modals.pathway_template_error.and' | translate }} </span>
    <span *ngIf="hasWarnings">{{ 'modals.pathway_template_error.warnings' | translate }} </span>
    <span>{{ 'modals.pathway_template_error.in_this_file' | translate }}</span>
  </h4>

  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close"
    (click)="onHandleClose()">
    <span [inlineSVG]="'./assets/svg/close.svg'"></span>
  </button>
</div>

<div class="modal-body py-0">
  <p>{{ 'modals.pathway_template_error.description' | translate }}</p>

  <p>{{ 'modals.pathway_template_error.uploaded_pathway_template' | translate }}</p>

  <div class="row no-gutters mb-2">
    <div class="col-8 py-2 bg-light d-flex align-items-center">
      <h3 class="mb-0"><span [inlineSVG]="'./assets/svg/multpage.svg'"></span></h3>
      <p class="mb-0">{{ pathwayTemplate.name}}</p>
    </div>
  </div>

  <div *ngIf="hasWarnings" class="warnings">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h4 class="text-muted font-weight-bold mb-0">
        {{ error.warnings.length }} {{ 'modals.pathway_template_error.warnings' | translate }}
      </h4>

      <button class="btn btn-link" (click)="warningsIsCollapsed = !warningsIsCollapsed"
        [attr.aria-expanded]="!warningsIsCollapsed" aria-controls="warningCollapse">

        <div *ngIf="warningsIsCollapsed">
          <span>{{ 'modals.pathway_template_error.view_details' | translate }}</span>
          <span [inlineSVG]="'./assets/svg/chevron-down.svg'" class="icon"></span>
        </div>

        <div *ngIf="!warningsIsCollapsed">
          <span>{{ 'modals.pathway_template_error.hide_details' | translate }}</span>
          <span [inlineSVG]="'./assets/svg/chevron-up.svg'" class="icon"></span>
        </div>

      </button>
    </div>

    <div id="warningCollapse" [collapse]="warningsIsCollapsed" [isAnimated]="true">
      <div *ngFor="let warning of error.warnings; last as last" class="warning">
        <div class="row no-gutters bg-light p-2 mb-1">
          <div class="col-6">
            <p class="mb-0 small text-muted">{{ 'modals.pathway_template_error.component_id' | translate }}</p>
            <p class="text-truncate">{{ warning.component_id }}</p>

            <p class="mb-0 small text-muted">{{ 'modals.pathway_template_error.validation_rule' | translate }}</p>
            <p class="text-truncate">{{ warning.validation_rule }}</p>

            <p class="mb-0 small text-muted">{{ 'modals.pathway_template_error.reason' | translate }}</p>
            <p class="mb-0 text-truncate">{{ warning.reason }}</p>
          </div>
          <div class="col-6">
            <p class="mb-0 small text-muted">{{ 'modals.pathway_template_error.component_type' | translate }}</p>
            <p class="text-truncate">{{ warning.component_type }}</p>

            <p class="mb-0 small text-muted">{{ 'modals.pathway_template_error.type' | translate }}</p>
            <p class="text-truncate">{{ warning.type }}</p>

            <p class="mb-0 small text-muted">{{ 'modals.pathway_template_error.reason_key' | translate }}</p>
            <p class="mb-0 text-truncate">{{ warning.reason_key }}</p>
          </div>
        </div>

        <hr class="my-1" *ngIf="!last" />
      </div>
    </div>
  </div>

  <hr />

  <div *ngIf="hasErrors" class="errors">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h4 class="text-muted font-weight-bold mb-0">
        {{ error.errors.length }} {{ 'modals.pathway_template_error.errors' | translate }}
      </h4>

      <button class="btn btn-link" (click)="errorsIsCollapsed = !errorsIsCollapsed"
        [attr.aria-expanded]="!errorsIsCollapsed" aria-controls="errorCollapse">

        <div *ngIf="errorsIsCollapsed">
          <span>{{ 'modals.pathway_template_error.view_details' | translate }}</span>
          <span [inlineSVG]="'./assets/svg/chevron-down.svg'" class="icon"></span>
        </div>

        <div *ngIf="!errorsIsCollapsed">
          <span>{{ 'modals.pathway_template_error.hide_details' | translate }}</span>
          <span [inlineSVG]="'./assets/svg/chevron-up.svg'" class="icon"></span>
        </div>

      </button>
    </div>

    <div id="errorCollapse" [collapse]="errorsIsCollapsed" [isAnimated]="true">
      <div *ngFor="let error of error.errors; last as last" class="error">
        <div class="row no-gutters bg-light p-2 mb-1">
          <div class="col-6">
            <p class="mb-0 small text-muted">{{ 'modals.pathway_template_error.component_id' | translate }}</p>
            <p class="text-truncate">{{ error.component_id }}</p>

            <p class="mb-0 small text-muted">{{ 'modals.pathway_template_error.validation_rule' | translate }}</p>
            <p class="text-truncate">{{ error.validation_rule }}</p>

            <p class="mb-0 small text-muted">{{ 'modals.pathway_template_error.reason' | translate }}</p>
            <p class="mb-0 text-truncate">{{ error.reason }}</p>
          </div>
          <div class="col-6">
            <p class="mb-0 small text-muted">{{ 'modals.pathway_template_error.component_type' | translate }}</p>
            <p class="text-truncate">{{ error.component_type }}</p>

            <p class="mb-0 small text-muted">{{ 'modals.pathway_template_error.type' | translate }}</p>
            <p class="text-truncate">{{ error.type }}</p>

            <p class="mb-0 small text-muted">{{ 'modals.pathway_template_error.reason_key' | translate }}</p>
            <p class="mb-0 text-truncate">{{ error.reason_key }}</p>
          </div>
        </div>

        <hr class="my-1" *ngIf="!last" />
      </div>
    </div>
  </div>

  <button class="btn btn-link d-flex align-items-center pl-0" (click)="exportList()">
    <span class="mr-1" [inlineSVG]="'./assets/svg/multpage.svg'"></span>
    <span> {{ 'modals.pathway_template_error.download_list' | translate }}</span>
  </button>
</div>

<div class="modal-footer">
  <div *ngIf="!hasWarnings && hasErrors" class="error-actions">
    <div class="m-start-auto">
      <button (click)="onHandleClose()" class="btn btn-link">
        {{ 'modals.pathway_template_error.cancel_upload' | translate }}
      </button>

      <input type="file" (change)="onHandleUploadNewFile($event)" id="file-btn" hidden />
      <label for="file-btn" class="btn btn-secondary m-start-3 mb-0">
        <span>{{ 'modals.pathway_template_error.upload_new_file' | translate }}</span>
      </label>
    </div>
  </div>
</div>
