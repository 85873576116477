<div class="modal-body">
  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close"
    (click)="bsModalRef.hide()">
    <span [inlineSVG]="'./assets/svg/close.svg'"></span>
  </button>

  <h4>{{ 'modals.set_hospital_code.title' | translate }}</h4>

  <app-page-loader *ngIf="!hospital"></app-page-loader>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}" *ngIf="hospital">
    <div class="form-group styled floating-label">
      <input type="text" id="code" class="form-control w-100" [placeholder]="'form.labels.code' | translate"
        formControlName="code">
      <label for="code" class="required">{{ 'form.labels.hospital_code' | translate }}</label>
      <div class="description small text-muted mt-1">{{ 'modals.set_hospital_code.description' | translate }}</div>
      <app-feedback-field [field]="form.get('code')"
        [meta]="{field_value: {min_length: 4, max_length: 4}}"></app-feedback-field>
    </div>

    <hr />

    <div class="form-group styled toggle" id="days-valid-indefinite">
      <label class="toggle-label mb-0">{{ 'form.labels.expiry_date' | translate }}</label>
      <app-toggle-switch formControlName="days_valid_indefinite" [inverse]="true" />
    </div>

    <div class="form-group styled floating-label" *ngIf="!form.get('days_valid_indefinite')?.value">
      <input type="number" id="days_valid" class="form-control w-100"
        [placeholder]="'form.labels.days_valid' | translate" formControlName="days_valid">
      <label for="days_valid" class="required">{{ 'form.labels.days_valid' | translate }}</label>
      <div class="description small text-muted mt-1">{{ 'modals.set_hospital_code.days_valid_description' | translate }}
      </div>
      <app-feedback-field [field]="form.get('days_valid')"
        [meta]="{field_value: {min: hospitalCodeDaysValidMin, max: hospitalCodeDaysValidMax}}"></app-feedback-field>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link">{{ 'action.cancel' | translate }}</button>

    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'modals.edit_cm_translation.save' | translate }}</span>
    </button>
  </div>
</div>
