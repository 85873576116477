<div class="pagination-component">
  <button class="btn"
          id="pagination-action-previous"
          (click)="actionPrev()"
          [ngClass]="{ 'disabled': (!activePage || activePage === 1) }">
    <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/chevron-left.svg'"></span>
  </button>

  <button class="btn pages ignore" id="pagination-has-more-before-first" *ngIf="hasMoreBeforeFirst()">
    ...
  </button>

  <button class="btn pages"
          [id]="['pagination-page-' + p]"
          *ngFor="let p of pagesList"
          (click)="actionSetPage(p)"
          [ngClass]="{ 'current': p === activePage }">
    {{ p }}
  </button>

  <button class="btn pages ignore" id="pagination-has-more-after-last" *ngIf="hasMoreAfterLast()">
    ...
  </button>

  <button class="btn"
          id="pagination-action-next"
          (click)="actionNext()"
          [ngClass]="{  'disabled': (!activePage || activePage === totalPages) }">
    <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/chevron-right.svg'"></span>
  </button>
</div>
