export const ENVIRONMENT_CONFIG = [
  {
    paths: [
      'https://careops.dev.eu.polyphonic.jnjmedtech.com',
      'https://dev-opsconsole.care4todayplatform.jnj.com',
      'https://d3p2ek2grifgup.cloudfront.net'
    ],
    color: '#008578',
    title: 'Development'
  },
  {
    paths: [
      'https://careops.pre-qa.eu.polyphonic.jnjmedtech.com',
      'https://pre-qa-opsconsole.care4todayplatform.jnj.com',
      'https://d2jl3abz9aj3i1.cloudfront.net'
    ],
    color: '#753BBD',
    title: 'Pre-Qa'
  },
  {
    paths: [
      'https://careops.qa.eu.polyphonic.jnjmedtech.com',
      'https://qa-opsconsole.care4todayplatform.jnj.com',
      'https://d1kiwtl99dka1g.cloudfront.net'
    ],
    color: '#FE5000',
    title: 'Quality assurance'
  },
  {
    paths: [
      'https://careops.uat.eu.polyphonic.jnjmedtech.com',
      'https://demo-opsconsole.care4todayplatform.jnj.com',
      'https://d2mmno9az7ohs4.cloudfront.net'
    ],
    color: '#00B5E2',
    title: 'Demo'
  },
  {
    paths: [
      'https://careops.eu.polyphonic.jnjmedtech.com',
      'https://admin.care4today.eu',
    ],
    color: '#CA001B',
    title: 'Production'
  }
];
