<div class="modal-body environment-select-modal">
  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'./assets/svg/close.svg'"></span>
  </button>

  <h4>Select environment</h4>

  <ul>
    <li *ngFor="let env of environments; let index = index;">
      <div class="background-color" [ngStyle]="{'background-color': env.color}"></div>
      <a [href]="env.paths[0]">
        <div class="d-flex">
          <div>
            <span class="logo" [inlineSVG]="'./assets/svg-color/polyphonic-hexagon.svg'"></span>
          </div>
          <div>
            <h2 class="title" [ngStyle]="{'color': env.color}">{{env.title}}</h2>
            <h3 class="subtitle" [ngStyle]="{'color': env.color}">Polyphonic</h3>
          </div>
        </div>
      </a>
    </li>
  </ul>
</div>
