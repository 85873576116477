<div class="modal-body">
  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close" (click)="onHandleClose()">
    <span [inlineSVG]="'./assets/svg/close.svg'"></span>
  </button>

  <h4>{{ 'modals.edit_cm_title.title' | translate }}</h4>

  <form [formGroup]="form">
    <div class="mt-3">
      <label class="small d-block required">Type</label>

      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input type="radio" class="custom-control-input" id="clinical" formControlName="type" value="CLINICAL">
        <label class="custom-control-label" for="clinical">Clinical</label>
      </div>

      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input type="radio" class="custom-control-input" id="educational" formControlName="type" value="EDUCATION">
        <label class="custom-control-label" for="educational">Educational</label>
      </div>
      <app-feedback-field [field]="form.get('type')"></app-feedback-field>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link" >{{ 'action.cancel' | translate }}</button>

    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'modals.edit_platform_consent.save_consent' | translate }}</span>
    </button>
  </div>
</div>

