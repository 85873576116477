<div class="panes-view">
  <div class="pane-list">
    <div class="layout-header">
      <div class="header-content">
        <div class="title-button">
          <h2>{{ 'pages.default.templates.title' | translate }}</h2>
          <button class="btn btn-secondary btn-circle" id="new-template-button" (click)="newTemplate()">
            <span class="icon" [inlineSVG]="'./assets/svg/add.svg'"></span>
          </button>
        </div>
      </div>
    </div>

    <app-page-loader *ngIf="isLoading"></app-page-loader>

    <div *ngIf="!isLoading" class="folders items pt-3">
      <div class="template-group d-flex py-1 w-100 p-start-2" [ngClass]="{'active': isSelected(parentGroup.path)}">
        <button class="btn p-0" (click)="toggleGroup(parentGroup)">
          <span *ngIf="loadingTemplatesFor !== parentGroup.path" class="chevron-right"
            [ngClass]="{'open': parentGroup.isOpen}" [inlineSVG]="'./assets/svg/chevron-right.svg'"></span>
          <span *ngIf="loadingTemplatesFor === parentGroup.path" class="text-info mr-1"><em
              class="spinner-border spinner-border-sm"></em></span>
        </button>

        <button class="btn p-0 d-flex align-items-center p-0 mb-0 ml-1 w-100" (click)="openGroup(parentGroup)">
          <span>{{ parentGroup.name }}</span>
        </button>
      </div>

      <ul *ngIf="parentGroup.isOpen">
        <ng-template #recursiveList let-groups>
          <li *ngFor="let group of groups">
            <div class="template-group d-flex py-1 w-100" [ngClass]="{'active': isSelected(group.path)}">
              <button class="btn" (click)="toggleGroup(group)">
                <span *ngIf="loadingTemplatesFor !== group.path" class="chevron-right"
                  [ngClass]="{'open': group.isOpen}" [inlineSVG]="'./assets/svg/chevron-right.svg'"></span>
                <span *ngIf="loadingTemplatesFor === group.path" class="text-info mr-1"><em
                    class="spinner-border spinner-border-sm"></em></span>
              </button>

              <button class="btn d-flex align-items-center p-0 mb-0 ml-1 w-100" (click)="openGroup(group)">
                <span class="mr-1" [inlineSVG]="'./assets/svg/folder-red.svg'"></span>
                <span>{{ group?.name }}</span>
              </button>
            </div>

            <ul *ngIf="group.isOpen">
              <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: group.groups }"></ng-container>

              <ul>
                <li *ngFor="let template of group?.templates" class="template"
                  [ngClass]="{'active': isSelected(template.uid)}">
                  <button class="btn d-flex align-items-center px-0 py-1 mb-0" (click)="selectTemplate(template)">
                    <span class="mr-1 ml-2" [inlineSVG]="'./assets/svg/template.svg'"></span>
                    <span class="text-truncate">{{ template.name }}</span>
                  </button>
                </li>
              </ul>
            </ul>
          </li>
        </ng-template>

        <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: groups }"></ng-container>

        <!-- Templates without folder -->
        <li *ngFor="let template of templates" class="templates">
          <button class="btn d-flex align-items-center px-0 py-1 mb-0 template"
            [ngClass]="{'active': isSelected(template.uid)}" (click)="selectTemplate(template)">
            <span class="mr-1 ml-2" [inlineSVG]="'./assets/svg/template.svg'"></span>
            <span class="text-truncate">{{ template.name }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>

  <div [ngSwitch]="currentView">
    <div *ngSwitchCase="detailViews.GROUP">
      <div class="pane-detail" *ngIf="groupDetail">
        <app-template-group-detail [group]="groupDetail" [(filter)]="filter" (templateSelected)="selectTemplate($event)"
          (groupSelected)="openGroup($event)" (updateScope)="refreshTemplatesAndGroups($event)" (addNewTemplate)="newTemplate($event)">
        </app-template-group-detail>
      </div>
    </div>

    <div *ngSwitchCase="detailViews.TEMPLATE">
      <div class="pane-detail" *ngIf="templateDetail">
        <app-pathway-template-detail [(template)]="templateDetail" (backToGroup)="backToGroup($event)"
          (openVersionDetails)="openVersionDetails($event)"
          (refreshViews)="refreshTemplatesAndGroups($event)"></app-pathway-template-detail>
      </div>
    </div>

    <div *ngSwitchCase="detailViews.VERSION">
      <div class="pane-detail" *ngIf="versionDetail && templateDetail">
        <app-pathway-template-version-detail [templateVersion]="versionDetail" [pathwayTemplate]="templateDetail"
          (backToTemplate)="selectTemplate($event)"></app-pathway-template-version-detail>
      </div>
    </div>

    <div *ngSwitchDefault>
      <div class="pane-detail empty">
        <div class="empty-state">
          <span [inlineSVG]="'./assets/svg-color/folder.svg'"></span>
          <h3>{{ 'pages.default.templates.empty.title' | translate }}</h3>
          <p>{{ 'pages.default.templates.empty.description' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
