<div class="layout-page-wrapper">
  <div class="layout-header-pusher header-filters"></div>

  <div class="layout-header header-filters">
    <div class="header-content">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">
            <h1>{{ 'pages.default.hospitals.hospitals' | translate }}</h1>
          </div>
          <div class="col col-auto">
            <button class="btn btn-sm btn-secondary" 
              (click)="newHospital($event)">{{ 'pages.default.hospitals.new_hospital' | translate }}</button>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">
            <div class="filters-group">
              <div class="form-group styled floating-label">
                <input type="text" id="searchHospitalsUid" class="form-control w-100" [placeholder]="('form.labels.uid' | translate)"
                [(ngModel)]="filters.uid"
                (ngModelChange)="hospitalsUidSearch.next($event)" required />
                <label for="searchHospitalsUid">{{ 'form.labels.uid' | translate }}</label>
              </div>
              <div class="divider"></div>
              <div class="form-group styled floating-label">
                <input type="text" id="searchHospitalsName" class="form-control w-100" [placeholder]="('form.labels.name' | translate)"
                [(ngModel)]="filters.name"
                (ngModelChange)="hospitalsNameSearch.next($event)" required />
                <label for="searchHospitalsName">{{ 'form.labels.name' | translate }}</label>
              </div>
              <div class="divider"></div>
              <div class="form-group styled floating-label">
                <input type="text" id="searchHospitalsCity" class="form-control w-100" [placeholder]="('form.labels.city' | translate)"
                [(ngModel)]="filters.city"
                (ngModelChange)="hospitalsCitySearch.next($event)" required />
                <label for="searchHospitalsCity">{{ 'form.labels.city' | translate }}</label>
              </div>
              <div class="divider" *ngIf="filtersAreSet"></div>

              <button type="button" class="btn btn-link text-info"  *ngIf="filtersAreSet" (click)="actionResetFilters()">{{ 'action.reset' | translate }}</button>
            </div>
          </div>

          <div class="col col-auto">

            <div class="btn-group text-dropdown text-dropdown-sm" dropdown>
              <button id="dropdown-btn-sort" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
                <span class="m-end-1">{{ 'general.sort_by' | translate }}:</span>

                <span *ngIf="sorting == 'name,asc'">{{ 'pages.default.hospitals.name_az' | translate }}&#x200E;</span>
                <span *ngIf="sorting == 'name,desc'">{{ 'pages.default.hospitals.name_za' | translate }}&#x200E;</span>


                <span class="icon" [inlineSVG]="'./assets/svg/chevron-down.svg'"></span>
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dropdown-btn-sort">
                <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSortDirectionAsc($event, 'name', true)">{{ 'pages.default.hospitals.name_az' | translate }}&#x200E; </a></li>
                <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSortDirectionAsc($event, 'name', false)">{{ 'pages.default.hospitals.name_za' | translate }}&#x200E; </a></li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="body-content">
    <div class="container" *ngIf="isLoading">
      <app-page-loader></app-page-loader>
    </div>

    <div class="container" *ngIf="!isLoading">
      <table class="table admin-table">
        <thead>
          <tr>
            <th scope="col">Active</th>
            <th scope="col">Name</th>
            <th scope="col">Address</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let hospital of hospitals; let index = index;">
            <th scope="row" class="icon-col">
              <span *ngIf="hospital?.active" [inlineSVG]="'./assets/svg/true.svg'"></span>
              <span *ngIf="!hospital?.active" [inlineSVG]="'./assets/svg/false.svg'"></span>
            </th>
            <td><strong>{{ hospital?.name }}</strong></td>
            <td>
              <span *ngIf="hospital?.site?.name">{{hospital?.site?.name}},</span>
              {{ hospital?.address?.number }} {{ hospital?.address?.street }},
              {{ hospital?.address?.city }} {{ hospital?.address?.postal_code }}, {{ getCountryLabel(hospital.address.country) | async }}
            </td>
            <td class="action bg-secondary">
              <a href="#" class="action-button" [routerLink]="['/hospitals/' + hospital?.uid]">
                <span [inlineSVG]="'./assets/svg/arrow-right-white.svg'"></span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <ng-container *ngIf="!isLoading && totalElements">
      <div class="align-items-center pagination-container">
        <!-- total items -->
        <div class="m-end-auto">{{ totalElements }} {{  totalElements > 1 ?  ('components.pagination.items_total' | translate) : ('components.pagination.items_total_singular' | translate) }}</div>
        <!-- pagination -->
        <div *ngIf="totalPages > 1"><app-pagination [totalPages]="totalPages" [activePage]="currentPage" (pageChange)="onPaginationPageChanged($event)"></app-pagination></div>
        <!-- pagination range -->
        <div class="m-start-auto"><app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)" [rangeOptions]="[5,10]"></app-pagination-range></div>
      </div>
    </ng-container>
  </div>
</div>
