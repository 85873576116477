<div class="modal-body">
  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'./assets/svg/close.svg'"></span>
  </button>

  <h4>Add new application</h4>

  <form [ngClass]="{'validation-visible': validationVisible}" [formGroup]="form">
    <div class="mt-3">
      <label class="small d-block required">{{ 'form.labels.active' | translate }}</label>

      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input type="radio" class="custom-control-input" id="activeTrue" formControlName="active" value="true">
        <label class="custom-control-label" for="activeTrue">{{ 'form.labels.yes' | translate }}</label>
      </div>

      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input type="radio" class="custom-control-input" id="activeFalse" formControlName="active" value="false">
        <label class="custom-control-label" for="activeFalse">{{ 'form.labels.no' | translate }}</label>
      </div>
      <app-feedback-field [field]="form.get('active')"></app-feedback-field>
    </div>

    <hr class="my-2">

    <div class="form-group styled floating-label">
      <input type="text" id="name" class="form-control w-100" [placeholder]="'form.labels.name' | translate" formControlName="name">
      <label for="name" class="required">{{ 'form.labels.name' | translate }}</label>
      <app-feedback-field [field]="form.get('name')"></app-feedback-field>
    </div>

    <div class="form-group styled floating-label">
      <input type="text" id="application_url" class="form-control w-100" [placeholder]="'form.labels.application_url' | translate" formControlName="application_url">
      <label for="application_url" class="required">{{ 'form.labels.application_url' | translate }}</label>
      <app-feedback-field [field]="form.get('application_url')"></app-feedback-field>
    </div>

    <div class="form-group required">
      <app-select
        id="type"
        [placeholder]="('form.labels.type' | translate)"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="types"
        [bindValue]="'value'"
        formControlName="type"
      ></app-select>
      <app-feedback-field [field]="form.get('type')"></app-feedback-field>
    </div>

    <!-- Start roles -->
    <div class="form-group styled mt-4">
      <label class="small d-block required">{{ 'form.labels.roles' | translate }}</label>
      <div class="custom-control custom-checkbox custom-control-inline mb-1">
        <input type="checkbox" class="custom-control-input" id="role_patient" formControlName="role_patient">
        <label class="custom-control-label" for="role_patient">{{ 'form.labels.patient' | translate }}</label>
      </div>
    </div>
    <div class="form-group styled">
      <div class="custom-control custom-checkbox custom-control-inline mb-1">
        <input type="checkbox" class="custom-control-input" id="role_hcp" formControlName="role_hcp">
        <label class="custom-control-label" for="role_hcp">{{ 'form.labels.hcp' | translate }}</label>
      </div>
    </div>
    <div class="form-group styled">
      <div class="custom-control custom-checkbox custom-control-inline mb-1">
        <input type="checkbox" class="custom-control-input" id="role_cc" formControlName="role_cc">
        <label class="custom-control-label" for="role_cc">{{ 'form.labels.cc' | translate }}</label>
      </div>
    </div>
    <div *ngIf="showRolesValidationMessage">
      <div class="text-danger">{{ 'modals.add_application.select_role_error' | translate }}</div>
    </div>
    <!-- End roles -->

    <hr class="my-2">

    <div class="form-group styled floating-label">
      <input type="text" id="minimum_required_version" class="form-control w-100" [placeholder]="'form.labels.minimum_required_version' | translate" formControlName="minimum_required_version">
      <label for="minimum_required_version" class="required">{{ 'form.labels.minimum_required_version' | translate }}</label>
      <app-feedback-field [field]="form.get('minimum_required_version')"></app-feedback-field>
    </div>

    <div class="form-group styled floating-label">
      <input type="number" id="session_time_out_minutes" class="form-control w-100" [placeholder]="'form.labels.session_time_out_minutes' | translate" formControlName="session_time_out_minutes">
      <label for="session_time_out_minutes" class="required">{{ 'form.labels.session_time_out_minutes' | translate }}</label>
      <app-feedback-field [field]="form.get('session_time_out_minutes')"></app-feedback-field>
    </div>

    <div class="form-group styled floating-label">
      <input type="text" id="secret" class="form-control w-100" [placeholder]="'form.labels.secret' | translate" formControlName="secret">
      <label for="secret" class="required">{{ 'form.labels.secret' | translate }}</label>
      <app-feedback-field [field]="form.get('secret')"></app-feedback-field>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link" >{{ 'action.cancel' | translate }}</button>

    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'modals.add_application.add_application' | translate }}</span>
    </button>
  </div>
</div>

