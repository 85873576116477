<div class="environment-indicator d-flex" [ngStyle]="{'background-color': this.currentEnvironment.color}" (click)="openSelector($event)" *ngIf="!readOnly">
  <span class="logo" [inlineSVG]="'./assets/svg-color/polyphonic-hexagon.svg'"></span>
  <div>
    <h2>{{this.currentEnvironment.title}}</h2>
    <h3>Polyphonic</h3>
  </div>
  <span class="chevron-down" [inlineSVG]="'./assets/svg/chevron-down.svg'" *ngIf="!readOnly"></span>
</div>

<div class="environment-indicator read-only d-flex" [ngStyle]="{'background-color': this.currentEnvironment.color}" *ngIf="readOnly">
  <span class="logo" [inlineSVG]="'./assets/svg-color/polyphonic-hexagon.svg'"></span>
  <div>
    <h2>{{this.currentEnvironment.title}}</h2>
    <h3>Polyphonic</h3>
  </div>
</div>
