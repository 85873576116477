<div class="modal-body">
  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'./assets/svg/close.svg'"></span>
  </button>

  <h4>Add new hospital</h4>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <h5>General</h5>
    <!-- Active -->
    <div class="pb-2">
      <label class="small d-block required">{{ 'form.labels.active' | translate }}</label>

      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input type="radio" class="custom-control-input" id="activeTrue" formControlName="active" value="true">
        <label class="custom-control-label" for="activeTrue">{{ 'form.labels.yes' | translate }}</label>
      </div>

      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input type="radio" class="custom-control-input" id="activeFalse" formControlName="active" value="false">
        <label class="custom-control-label" for="activeFalse">{{ 'form.labels.no' | translate }}</label>
      </div>
    </div>

    <!-- Name -->
    <div class="form-group styled floating-label">
      <input type="text" id="name" class="form-control w-100" [placeholder]="'form.labels.name' | translate" formControlName="name">
      <label for="name" class="required">{{ 'form.labels.name' | translate }}</label>
      <app-feedback-field [field]="form.get('name')"></app-feedback-field>
    </div>

    <!-- Website -->
    <div formGroupName="site">
      <div class="form-group styled floating-label">
        <input type="text" id="site_name" class="form-control w-100" [placeholder]="'form.labels.website_name' | translate" formControlName="name">
        <label for="site_name" class="required">{{ 'form.labels.website_name' | translate }}</label>
        <app-feedback-field [field]="form.get('site.name')"></app-feedback-field>
      </div>

      <div class="form-group styled floating-label">
        <input type="text" id="site_url" class="form-control w-100" [placeholder]="'form.labels.website_url' | translate" formControlName="url">
        <label for="site_url" class="required">{{ 'form.labels.website_url' | translate }}</label>
        <app-feedback-field [field]="form.get('site.url')"></app-feedback-field>
      </div>
    </div>

    <!-- CMS Ref Logo -->
    <div class="form-group styled floating-label" formGroupName= "logo">
      <input type="text" id="cms_ref_logo" class="form-control w-100" [placeholder]="'form.labels.cms_ref_logo' | translate" formControlName="key">
      <label for="cms_ref_logo" class="required">{{ 'form.labels.cms_ref_logo' | translate }}</label>
      <app-feedback-field [field]="form.get('logo.key')"></app-feedback-field>
    </div>

    <!-- CMS Ref Metadata -->
    <div class="form-group styled floating-label" formGroupName= "metadata"> 
      <input type="text" id="cms_ref_metadata" class="form-control w-100" [placeholder]="'form.labels.cms_ref_metadata' | translate" formControlName="key">
      <label for="cms_ref_metadata" class="required">{{ 'form.labels.cms_ref_metadata' | translate }}</label>
      <app-feedback-field [field]="form.get('metadata.key')"></app-feedback-field>
    </div>

    <h5>Contact</h5>
    <!-- Phone number -->
    <div class="row validation-group" formGroupName="phone_number">
      <div class="col-5">
        <div class="form-group styled floating-label required">
          <app-select
            [placeholder]="('form.labels.country_code' | translate)"
            [clearable]="false"
            [searchable]="true"
            [hideSelected]="false"
            [multiple]="false"
            [items]="dialCodeOptions"
            [bindValue]="'value'"

            [autoSortOnLabel]="true"
            [selectedItemsOnTop]="false"

            formControlName="code"

            [asFilter]="false"
            [showCheckboxes]="false"
          ></app-select>
          <app-feedback-field [field]="form.get('phone_number.code')"></app-feedback-field>
        </div>
      </div>
      <div class="col-7">
        <div class="form-group styled floating-label">
          <input [placeholder]="('form.labels.phone_number' | translate)" class="form-control w-100"
                formControlName="number" id="inputPhone" type="tel">
          <label for="inputPhone" class="required">{{ 'form.labels.phone_number' | translate }}</label>
          <app-feedback-field [field]="form.get('phone_number.number')"></app-feedback-field>

        </div>
      </div>
    </div>
    <app-feedback-field [field]="form.get('phone_number')"></app-feedback-field>

    <!-- Email -->
    <div class="form-group styled floating-label"> 
      <input type="text" id="email" class="form-control w-100" [placeholder]="'form.labels.email_address' | translate" formControlName="email">
      <label for="email" class="required">{{ 'form.labels.email_address' | translate }}</label>
      <app-feedback-field [field]="form.get('email')"></app-feedback-field>
    </div>

    <h5>Address</h5>
    <!-- Address -->
    <div formGroupName="address">
      <div class="row">
        <div class="col-6">
          <div class="form-group styled floating-label">
            <input type="text" id="street" class="form-control w-100" [placeholder]="'form.labels.street' | translate" formControlName="street">
            <label for="street" class="required">{{ 'form.labels.street' | translate }}</label>
            <app-feedback-field [field]="form.get('address.street')"></app-feedback-field>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group styled floating-label">
            <input type="text" id="number" class="form-control w-100" [placeholder]="'form.labels.number' | translate" formControlName="number">
            <label for="number" class="required">{{ 'form.labels.number' | translate }}</label>
            <app-feedback-field [field]="form.get('address.number')"></app-feedback-field>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group styled floating-label">
            <input type="text" id="bus" class="form-control w-100" [placeholder]="'form.labels.bus' | translate" formControlName="bus">
            <label for="bus">{{ 'form.labels.bus' | translate }}</label>
            <app-feedback-field [field]="form.get('address.bus')"></app-feedback-field>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <div class="form-group styled floating-label">
            <input type="text" id="postal_code" class="form-control w-100" [placeholder]="'form.labels.postal_code' | translate" formControlName="postal_code">
            <label for="postal_code" class="required">{{ 'form.labels.postal_code' | translate }}</label>
            <app-feedback-field [field]="form.get('address.postal_code')"></app-feedback-field>
          </div>
        </div>
        <div class="col-7">
          <div class="form-group styled floating-label">
            <input type="text" id="city" class="form-control w-100" [placeholder]="'form.labels.city' | translate" formControlName="city">
            <label for="city" class="required">{{ 'form.labels.city' | translate }}</label>
            <app-feedback-field [field]="form.get('address.city')"></app-feedback-field>
          </div>
        </div>
      </div>
      <div class="form-group required">
        <app-select
          id="country"
          [placeholder]="('form.labels.country' | translate)"
          [searchable]="true"
          [hideSelected]="false"
          [multiple]="false"
          [items]="countries"
          [bindValue]="'value'"
          formControlName="country"
        ></app-select>
        <app-feedback-field [field]="form.get('address.country')"></app-feedback-field>

      </div>
    </div>

    <h5>Default preferences</h5>
    <div formGroupName="default_locale">
        <div class="form-group required">
          <app-select
            id="preference-languages"
            [placeholder]="('form.labels.language' | translate)"
            [searchable]="true"
            [hideSelected]="false"
            [multiple]="false"
            [items]="languages"
            [bindValue]="'key'"
            formControlName="language"
          ></app-select>
          <app-feedback-field [field]="form.get('default_locale.language')"></app-feedback-field>
      </div>
      <div class="form-group required">
        <app-select
          id="preference-measurement-unit"
          [placeholder]="('form.labels.units' | translate)"
          [searchable]="true"
          [hideSelected]="false"
          [multiple]="false"
          [items]="measurementUnits"
          [bindValue]="'value'"
          formControlName="measurement_unit"
        ></app-select>
        <app-feedback-field [field]="form.get('default_locale.measurement_unit')"></app-feedback-field>

      </div>

      <div class="form-group required">
        <app-select
          id="preference-number-format"
          [placeholder]="('form.labels.number_format' | translate)"
          [searchable]="true"
          [hideSelected]="false"
          [multiple]="false"
          [items]="numberFormats"
          [bindValue]="'value'"
          formControlName="number_format"
        ></app-select>
        <app-feedback-field [field]="form.get('default_locale.number_format')"></app-feedback-field>

      </div>

      <div class="form-group required">
        <app-select
          id="preference-date-format"
          [placeholder]="('form.labels.date_format' | translate)"
          [searchable]="true"
          [hideSelected]="false"
          [multiple]="false"
          [items]="dateFormats"
          [bindValue]="'value'"
          formControlName="date_format"
        ></app-select>
        <app-feedback-field [field]="form.get('default_locale.date_format')"></app-feedback-field>

      </div>

      <div class="form-group required">
        <app-select
          id="preference-time-zone"
          [placeholder]="('form.labels.time_zone' | translate)"
          [searchable]="true"
          [hideSelected]="false"
          [multiple]="false"
          [items]="timeZones"
          [bindValue]="'value'"
          formControlName="time_zone"
        ></app-select>
        <app-feedback-field [field]="form.get('default_locale.time_zone')"></app-feedback-field>

      </div>

      <div class="form-group required">
        <app-select
          id="preference-time-24-hours"
          [placeholder]="('form.labels.time_format' | translate)"
          [searchable]="false"
          [hideSelected]="false"
          [multiple]="false"
          [items]="timeFormats"
          [bindValue]="'value'"
          formControlName="time_24_hours"
        ></app-select>
        <app-feedback-field [field]="form.get('default_locale.time_24_hours')"></app-feedback-field>

      </div>

      <div class="form-group required">
        <app-select
          id="preference-first-day-of-week"
          [placeholder]="('form.labels.first_day_of_week' | translate)"
          [searchable]="false"
          [hideSelected]="false"
          [multiple]="false"
          [items]="weekdays"
          [bindValue]="'value'"
          formControlName="first_day_of_week"
        ></app-select>
        <app-feedback-field [field]="form.get('default_locale.first_day_of_week')"></app-feedback-field>

      </div>
    </div>

    <!-- Start: Modules --> 
    <div formGroupName="modules">
      <div class="form-group d-flex justify-content-between pt-3">
        <label class="toggle-label">{{ 'modules.TREATMENTS' | translate }}</label>
        <label class="toggle-label">{{ 'modules.always_on' | translate }}</label>
      </div>
      
      <div class="form-group d-flex justify-content-between align-items-center" id="messaging">
        <label class="toggle-label text-dark mb-0">{{ 'modules.MESSAGING' | translate }}</label>
        <app-toggle-switch formControlName="messaging"/>
      </div>

      <div class="form-group d-flex justify-content-between align-items-center" id="appointment">
        <label class="toggle-label text-dark mb-0">{{ 'modules.APPOINTMENT' | translate }}</label>
        <app-toggle-switch formControlName="appointment"/>
      </div>
      
      <div formGroupName="analytics">
        <div class="form-group d-flex justify-content-between align-items-center" id="analytics-general">
          <label class="toggle-label text-dark mb-0">{{ 'modules.ANALYTICS.general' | translate }}</label>
          <app-toggle-switch formControlName="general"/>
        </div>
        
        <div class="form-group styled toggle m-start-2" id="analytics-pathway-overview">
          <label class="toggle-label mb-0" [ngClass]="{'required': form.get('modules.analytics.general')?.value}">{{ 'modules.ANALYTICS.pathways_overview' | translate }}</label>
          <app-toggle-switch formControlName="pathways_overview"/>
          <app-feedback-field [field]="form.get('modules.analytics.pathways_overview')"/>
        </div>
        
        <div class="form-group styled toggle m-start-2" id="analytics-patient-onboarding">
          <label class="toggle-label mb-0" [ngClass]="{'required': form.get('modules.analytics.general')?.value}">{{ 'modules.ANALYTICS.patient_onboarding' | translate }}</label>
          <app-toggle-switch formControlName="patient_onboarding"/>
          <app-feedback-field [field]="form.get('modules.analytics.patient_onboarding')"/>
        </div>
        
      </div>
    </div>
    <!-- End: Modules -->

    <h5>Registration codes</h5>
    
    <!-- hospital_code_days_valid-->
    <div formGroupName="general_configuration">
      <div class="form-group d-flex justify-content-between align-items-center" id="hospital-code-days-valid-indefinite">
        <label class="toggle-label text-dark mb-0">{{ 'form.labels.expiry_date' | translate }}</label>
        <app-toggle-switch formControlName="hospital_code_days_valid_indefinite" [inverse]="true" />
      </div>
      
      <div class="form-group styled floating-label" *ngIf="!form.get('general_configuration')?.get('hospital_code_days_valid_indefinite')?.value">
        <input type="number" id="hospital_code_days_valid" class="form-control w-100" [placeholder]="'form.labels.hospital_code_days_valid' | translate" formControlName="hospital_code_days_valid">
        <label for="hospital_code_days_valid" class="required">{{ 'form.labels.hospital_code_days_valid' | translate }}</label>
        <app-feedback-field [field]="form.get('general_configuration.hospital_code_days_valid')" [meta]="{field_value: {min: hospitalCodeDaysValidMin, max: hospitalCodeDaysValidMax}}"></app-feedback-field>
      </div>
  </div>

    <h5>Password policy</h5>
    <div formGroupName="security_configuration">
      <div formGroupName="password_policy">
        <div class="form-group required">
          <app-select
            id="mfa"
            [placeholder]="('form.labels.use_2_step_authentication' | translate)"
            [searchable]="false"
            [hideSelected]="false"
            [multiple]="false"
            [items]="options"
            [bindValue]="'value'"
            formControlName="mfa"
          ></app-select>
          <app-feedback-field [field]="form.get('security_configuration.password_policy.mfa')"></app-feedback-field>
        </div>
        <div class="form-group required">
          <app-select
            id="use_lowercase"
            [placeholder]="('form.labels.lowercase_required' | translate)"
            [searchable]="false"
            [hideSelected]="false"
            [multiple]="false"
            [items]="options"
            [bindValue]="'value'"
            formControlName="use_lowercase"
          ></app-select>
          <app-feedback-field [field]="form.get('security_configuration.password_policy.use_lowercase')"></app-feedback-field>
        </div>
        <div class="form-group required">
          <app-select
            id="use_uppercase"
            [placeholder]="('form.labels.uppercase_required' | translate)"
            [searchable]="false"
            [hideSelected]="false"
            [multiple]="false"
            [items]="options"
            [bindValue]="'value'"
            formControlName="use_uppercase"
          ></app-select>
          <app-feedback-field [field]="form.get('security_configuration.password_policy.use_uppercase')"></app-feedback-field>
        </div>
        <div class="form-group required">
          <app-select
            id="use_numbers"
            [placeholder]="('form.labels.numbers_required' | translate)"
            [searchable]="false"
            [hideSelected]="false"
            [multiple]="false"
            [items]="options"
            [bindValue]="'value'"
            formControlName="use_numbers"
          ></app-select>
          <app-feedback-field [field]="form.get('security_configuration.password_policy.use_numbers')"></app-feedback-field>
        </div>
        <div class="form-group required">
          <app-select
            id="use_specials"
            [placeholder]="('form.labels.symbols_required' | translate)"
            [searchable]="false"
            [hideSelected]="false"
            [multiple]="false"
            [items]="options"
            [bindValue]="'value'"
            formControlName="use_specials"
          ></app-select>
          <app-feedback-field [field]="form.get('security_configuration.password_policy.use_specials')"></app-feedback-field>
        </div>
        <div class="form-group styled floating-label">
          <input type="text" id="min_length" class="form-control w-100" [placeholder]="'form.labels.min_password_length' | translate" formControlName="min_length">
          <label for="min_length" class="required">{{ 'form.labels.min_password_length' | translate }}</label>
          <app-feedback-field [field]="form.get('security_configuration.password_policy.min_length')" [meta]="{field_value: {min: minPWLengthMin, max: minPWLengthMax}}"></app-feedback-field>
        </div>
        <div class="form-group styled floating-label">
          <input type="text" id="max_length" class="form-control w-100" [placeholder]="'form.labels.max_password_length' | translate" formControlName="max_length">
          <label for="max_length" class="required">{{ 'form.labels.max_password_length' | translate }}</label>
          <app-feedback-field [field]="form.get('security_configuration.password_policy.max_length')" [meta]="{field_value: {min: maxPWLengthMin, max: maxPWLengthMax}}"></app-feedback-field>
        </div>

        <div class="form-group required">
          <app-select
            id="history"
            [placeholder]="('form.labels.cant_reuse_password_from_the_past' | translate)"
            [searchable]="false"
            [hideSelected]="false"
            [multiple]="false"
            [items]="booleanList"
            [bindValue]="'value'"
            formControlName="history"
          ></app-select>
          <app-feedback-field [field]="form.get('security_configuration.password_policy.history')"></app-feedback-field>
        </div>

        <div class="form-group styled floating-label">
          <input type="text" id="history_size" class="form-control w-100" [placeholder]="'form.labels.amount_passwords_to_remember' | translate" formControlName="history_size">
          <label for="history_size" class="required">{{ 'form.labels.amount_passwords_to_remember' | translate }}</label>
          <app-feedback-field [field]="form.get('security_configuration.password_policy.history_size')" [meta]="{field_value: {min: historySizeMin, max: historySizeMax}}"></app-feedback-field>
        </div>

        <div class="form-group required">
          <app-select
            id="expires"
            [placeholder]="('form.labels.password_expires' | translate)"
            [searchable]="false"
            [hideSelected]="false"
            [multiple]="false"
            [items]="booleanList"
            [bindValue]="'value'"
            formControlName="expires"
          ></app-select>
          <app-feedback-field [field]="form.get('security_configuration.password_policy.expires')"></app-feedback-field>
        </div>

        <div class="form-group styled floating-label">
          <input type="text" id="expires_in_days" class="form-control w-100" [placeholder]="'form.labels.amount_days_expires' | translate" formControlName="expires_in_days">
          <label for="expires_in_days" class="required">{{ 'form.labels.amount_days_expires' | translate }}</label>
          <app-feedback-field [field]="form.get('security_configuration.password_policy.expires_in_days')" [meta]="{field_value: {min: expiresInDaysMin, max: expiresInDaysMax}}"></app-feedback-field>
        </div>
        
        <div class="form-group styled floating-label">
          <input type="text" id="expiry_notification_in_days" class="form-control w-100" [placeholder]="'form.labels.expiry_notification_in_days' | translate" formControlName="expiry_notification_in_days">
          <label for="expiry_notification_in_days" class="required">{{ 'form.labels.expiry_notification_in_days' | translate }}</label>
          <app-feedback-field [field]="form.get('security_configuration.password_policy.expiry_notification_in_days')" [meta]="{field_value: {min: expiryNotificationInDaysMin, max: expiryNotificationInDaysMax}}"></app-feedback-field>
        </div>
      </div>
    </div>
  </form>
  
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link" >{{ 'action.cancel' | translate }}</button>

    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'modals.add_hospital.add_hospital' | translate }}</span>
    </button>
  </div>
</div>

