<div class="modal-body">
  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'./assets/svg/close.svg'"></span>
  </button>

  <h4>{{ 'modals.change_password.title' | translate }}</h4>

  <form [formGroup]="form" id="form" (ngSubmit)="formSubmit()" [ngClass]="{'validation-visible': validationVisible}">
    <div class="form-group styled floating-label width-limit">
      <input type="password" id="inputCurrentPassword" class="form-control w-100" [placeholder]="('modals.change_password.current_pw'|translate)" formControlName="old_password">
      <label for="inputCurrentPassword" class="required">{{ 'modals.change_password.current_pw' | translate }}</label>
      <p class="validation-feedback" *ngIf="form?.old_password?.errors?.password_invalid">{{ 'form.feedback.current_pw_incorrect' | translate }}</p>
    </div>

    <div class="form-group styled floating-label width-limit">
      <input type="password" id="inputNewPassword" class="form-control w-100" [placeholder]="('modals.change_password.new_pw'|translate)" formControlName="new_password" [ngClass]="{'ng-invalid-important': (form?.errors?.mismatch)}">
      <label for="inputNewPassword" class="required">{{ 'modals.change_password.new_pw' | translate }}</label>
      <p class="validation-feedback" *ngIf="form?.controls?.new_password?.errors?.password_policy">{{ 'modals.change_password.pw_complexity_error' | translate }}</p>
      <p class="validation-feedback" *ngIf="form?.controls?.new_password?.errors?.password_already_used">{{ 'modals.change_password.pw_already_used' | translate:{value: policy.history_size} }}</p>
    </div>

    <div class="form-group styled floating-label width-limit">
      <input type="password" id="inputConfirmNewPassword" class="form-control w-100" [placeholder]="('modals.change_password.confirm_pw'|translate)" formControlName="new_password_verification" [ngClass]="{'ng-invalid-important': form?.errors?.mismatch}">
      <label for="inputConfirmNewPassword" class="required">{{ 'modals.change_password.confirm_pw' | translate }}</label>
      <p class="validation-feedback general-feedback" *ngIf="form?.errors?.mismatch">{{ 'modals.change_password.pw_not_the_same' | translate }}</p>
    </div>
  </form>

  <div class="bg-light p-3">
    <app-password-policy-rules [policy]="policy" [validationVisible]="validationVisible" [passwordControl]="form?.new_password" ></app-password-policy-rules>
  </div>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link" >{{ 'action.cancel' | translate }}</button>

    <button (click)="formSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'modals.change_password.change_pw' | translate }}</span>
    </button>
  </div>
</div>
