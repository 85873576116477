<div class="modal-body">
  <button type="button" class="close" id="logout-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'./assets/svg/close.svg'"></span>
  </button>

  <h4>{{ 'modals.edit_hospital.edit_general_information' | translate }}</h4>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <!-- Active -->
    <div class="pb-2">
      <label class="small d-block required">{{ 'form.labels.active' | translate }}</label>

      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input type="radio" class="custom-control-input" id="activeTrue" formControlName="active" value="true">
        <label class="custom-control-label" for="activeTrue">{{ 'form.labels.yes' | translate }}</label>
      </div>

      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input type="radio" class="custom-control-input" id="activeFalse" formControlName="active" value="false">
        <label class="custom-control-label" for="activeFalse">{{ 'form.labels.no' | translate }}</label>
      </div>
    </div>

    <!-- Name -->
    <div class="form-group styled floating-label">
      <input type="text" id="name" class="form-control w-100" [placeholder]="'form.labels.name' | translate" formControlName="name">
      <label for="name" class="required">{{ 'form.labels.name' | translate }}</label>
      <app-feedback-field [field]="form.get('name')"></app-feedback-field>
    </div>

    <!-- Website -->
    <div formGroupName="site">
      <div class="form-group styled floating-label">
        <input type="text" id="site_name" class="form-control w-100" [placeholder]="'form.labels.website_name' | translate" formControlName="name">
        <label for="site_name" class="required">{{ 'form.labels.website_name' | translate }}</label>
        <app-feedback-field [field]="form.get('site.name')"></app-feedback-field>
      </div>

      <div class="form-group styled floating-label">
        <input type="text" id="site_url" class="form-control w-100" [placeholder]="'form.labels.website_url' | translate" formControlName="url">
        <label for="site_url" class="required">{{ 'form.labels.website_url' | translate }}</label>
        <app-feedback-field [field]="form.get('site.url')"></app-feedback-field>
      </div>
    </div>

    <!-- CMS Ref Logo -->
    <div class="form-group styled floating-label" formGroupName= "logo">
      <input type="text" id="cms_ref_logo" class="form-control w-100" [placeholder]="'form.labels.cms_ref_logo' | translate" formControlName="key">
      <label for="cms_ref_logo">{{ 'form.labels.cms_ref_logo' | translate }}</label>
      <app-feedback-field [field]="form.get('logo.key')"></app-feedback-field>
    </div>

    <!-- CMS Ref Metadata -->
    <div class="form-group styled floating-label" formGroupName= "metadata"> 
      <input type="text" id="cms_ref_metadata" class="form-control w-100" [placeholder]="'form.labels.cms_ref_metadata' | translate" formControlName="key">
      <label for="cms_ref_metadata">{{ 'form.labels.cms_ref_metadata' | translate }}</label>
      <app-feedback-field [field]="form.get('metadata.key')"></app-feedback-field>
    </div>
  </form>
  
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleClose()" class="btn btn-link" >{{ 'action.cancel' | translate }}</button>

    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>
  </div>
</div>
