<div class="layout-header-pusher header-back-tabs"></div>

<div class="layout-header header-back-tabs">
  <div class="header-content">
    <div class="container">
      <p>
        <a [routerLink]="['/hospitals']" class="text-secondary font-weight-bold d-flex align-items-center">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'./assets/svg/left-arrow-big.svg'"></span>
          <span class="align-middle">{{ 'action.back_to_hospitals' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col col-auto">
          <h1>{{ hospital?.name }}</h1>
        </div>
        <div class="col col-auto">
          <button class="btn btn-sm btn-secondary" (click)="addCareCoordinator($event)">{{ 'pages.default.care_coordinators.new' | translate }}</button>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <app-hospital-tabs [hospital]="hospital" [active]="'care_coordinators'"></app-hospital-tabs>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">
    <p class="text-muted" *ngIf="care_coordinators.length === 0">
      {{ 'pages.default.care_coordinators.empty' | translate }}
    </p>
    <table class="table admin-table" *ngIf="care_coordinators.length > 0">
      <thead>
        <tr>
          <th scope="col">{{ 'pages.default.care_coordinators.name' | translate }}</th>
          <th scope="col">{{ 'pages.default.care_coordinators.email' | translate }}</th>
          <th scope="col">{{ 'pages.default.care_coordinators.language' | translate }}</th>
          <th scope="col">{{ 'pages.default.care_coordinators.status' | translate }}</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let care_coordinator of care_coordinators; let index = index;">
          <td><strong>{{ care_coordinator?.getFullName() }}</strong></td>
          <td>
            <a href="mailto:{{ care_coordinator?.email }}" class="text-info text-icon-link" target="_blank">
              <span class="label">{{ care_coordinator?.email }}</span>
              <span class="icon-end" [inlineSVG]="'./assets/svg/external.svg'"></span>
            </a>
          </td>
          <td>{{ care_coordinator?.language }}</td>
          <td>{{ care_coordinator?.status }}</td>
          <td class="action">
            <a href="#" class="action-button" (click)="editCareCoordinator($event, care_coordinator)">
              <span [inlineSVG]="'./assets/svg/edit.svg'"></span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <ng-container *ngIf="!isLoading && totalElements">
    <div class="align-items-center pagination-container">
      <!-- total items -->
      <div class="m-end-auto">{{ totalElements }} {{  totalElements > 1 ?  ('components.pagination.items_total' | translate) : ('components.pagination.items_total_singular' | translate) }}</div>
      <!-- pagination -->
      <div *ngIf="totalPages > 1"><app-pagination [totalPages]="totalPages" [activePage]="currentPage" (pageChange)="onPaginationPageChanged($event)"></app-pagination></div>
      <!-- pagination range -->
      <div class="m-start-auto"><app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)" [rangeOptions]="[5,10]"></app-pagination-range></div>
    </div>
  </ng-container>
</div>
