<div class="layout-header-pusher header-title-only"></div>
<div class="layout-page-wrapper">
  <div class="layout-header">
    <div class="header-content">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">
            <h1>{{ 'pages.default.applications.title' | translate }}</h1>
          </div>
          <div class="col col-auto">
            <button class="btn btn-sm btn-secondary" (click)="newApplication($event)">{{ 'pages.default.applications.new' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="body-content">
    <div class="container" *ngIf="isLoading">
      <app-page-loader></app-page-loader>
    </div>

    <div class="container" *ngIf="!isLoading">
      <table class="table admin-table">
        <thead>
          <tr>
            <th scope="col">{{ 'pages.default.applications.active' | translate }}</th>
            <th scope="col">{{ 'pages.default.applications.name' | translate }}</th>
            <th scope="col">{{ 'pages.default.applications.type' | translate }}</th>
            <th scope="col" class="center">{{ 'pages.default.applications.min_version' | translate }}</th>
            <th scope="col" class="center">{{ 'pages.default.applications.consents' | translate }}</th>
            <th></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let application of applications; let index = index;">
            <td class="center">
              <span *ngIf="application?.active" class="icon" [inlineSVG]="'./assets/svg/check-free.svg'"></span>
              <span *ngIf="!application?.active" class="icon" [inlineSVG]="'./assets/svg/false.svg'"></span>
            </td>
            <td><strong>{{ application.name }}</strong></td>
            <td>{{application.type}}</td>
            <td class="center">{{application.minimum_version}}</td>
            <td class="center" [ngClass]="{'text-faded': application.consents.length === 0}">{{application.consents.length}}</td>

            <td class="action">
              <a href="#" class="action-button secondary" (click)="editApplication($event, application)">
                <span [inlineSVG]="'./assets/svg/edit-black.svg'"></span>
              </a>
            </td>
            <td class="action">
              <a href="#" class="action-button" [routerLink]="['/applications/' + application?.uid + '/consents']">
                <span [inlineSVG]="'./assets/svg/arrow-right-white.svg'"></span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ng-container *ngIf="!isLoading && totalElements">
      <div class="align-items-center pagination-container">
        <!-- total items -->
        <div class="m-end-auto">{{ totalElements }} {{  totalElements > 1 ?  ('components.pagination.items_total' | translate) : ('components.pagination.items_total_singular' | translate) }}</div>
        <!-- pagination -->
        <div *ngIf="totalPages > 1"><app-pagination [totalPages]="totalPages" [activePage]="currentPage" (pageChange)="onPaginationPageChanged($event)"></app-pagination></div>
        <!-- pagination range -->
        <div class="m-start-auto"><app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)" [rangeOptions]="[5,10]"></app-pagination-range></div>
      </div>
    </ng-container>
  </div>
  
</div>
