<div class="modal-body">
  <button type="button" class="close" id="edit-other-email-modal-close" data-dismiss="modal" aria-label="Close"
    (click)="handleClose()">
    <span [inlineSVG]="'./assets/svg/close.svg'"></span>
  </button>
  <h2>{{ 'modals.edit_other_email.edit_email' | translate}}</h2>
  <p class="m-0 small text-muted">{{ 'modals.edit_other_email.current_email' | translate}}</p>
  <p class="mb-3">{{ cc?.email }}</p>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}" autocomplete="off">
    <!-- new email -->
    <div class="form-group styled floating-label">
      <input type="email" id="inputNewEmail" class="form-control w-100"
        [placeholder]="('form.labels.new_email' | translate)" formControlName="new_email">
      <label for="inputNewEmail" class="required">{{ 'modals.edit_other_email.new_email' | translate }}</label>
      <app-feedback-field [field]="form.get('new_email')"></app-feedback-field>
    </div>

    <div class="form-group styled required floating-label">
      <textarea autosize id="inputReason" class="form-control w-100" formControlName="reason"
        [placeholder]="'modals.edit_other_email.reason' | translate" required [maxlength]="reasonMaxLength"></textarea>
      <label for="inputReason" class="required">{{ 'modals.edit_other_email.reason' | translate }}</label>
      <app-feedback-field [field]="form.get('reason')"></app-feedback-field>
      <p class="small text-muted text-align-end m-0" *ngIf="!(validationVisible && !form?.controls?.reason?.value)">
        {{( form.get('reason')?.value?.length || '0' ) }}/{{reasonMaxLength}}
      </p>

      <p class="text-muted small">{{ 'modals.edit_other_email.change_time' | translate }}</p>
    </div>
  </form>


</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" id="edit-other-email-modal-no" (click)="handleClose()">{{ ('action.cancel' | translate
      ) }}</button>
    <button class="btn btn-secondary m-start-3" id="edit-other-email-modal-submit" (click)="handleSubmit()"
      [ngClass]="{'loader': isSaving}">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span>{{ ('action.confirm' | translate ) }}</span>
    </button>
  </div>
</div>
